<template>
    <div>
        <div class="modal bg-black" tabindex="-1" v-if="modalPacientOpened">
            <div class="modal-dialog modal-mg side-modal" >
                <div class="modal-content">
                    <div class="modal-header">
                        <a href="javascript:;" type="button" class="btn-close" @click="cerrarModalEnviar()"><i class="fa fa-times"></i></a>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div style="width: 100%; height: 100%; margin-right: 0;" class="card">
                                <div style="width: 100%; height: 100%; border: none; max-height: 320px;" class="card-body"><img style="width: 100%; height: 100%; max-height: 300px;" crossorigin="anonymous" :src="card_seleccionada.url" /></div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer" style="display: flex; justify-content: space-evenly;">
                        <div class="row" style="width: 80%;">
                            <button type="button" class="default-btn submit-btn" @click="emparentarTarjetaSeleccionada(card_seleccionada)">SELECCIONAR<span></span></button>
                            <button type="button" style="margin-top:0px; margin-left: 5%;" class="default-btn-one submit-btn" data-bs-dismiss="modal" @click="cerrarModalEnviar()">CANCELAR<span></span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <title-section></title-section>
    
        <section class="services-section section-padding">
            <div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="section-title">
                            <router-link to="/my-account/rooms">
                                <i class="fa fa-arrow-left mr-2"></i>Regresar
                            </router-link>
                            <h2>Grupo {{ name }}</h2>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-lg-12">
                         <div v-if="mensajeError != ''" class="alert alert-danger mb-4" role="alert">
                            <i class="fa fa-exclamation-triangle"></i> <strong>Atención. </strong> <span>{{ mensajeError }}</span>
                        </div>

                        <div v-if="mensajeExito != ''" class="alert alert-success mb-4" role="alert">
                            <i class="fa fa-check"></i> <strong>Excelente.</strong> <span>{{ mensajeExito }}</span>
                        </div>

                        <div style="width: 1280px;" class="row">

                            <div class="col-md-2">          
                                <div>
                                    <button type="button" class="default-btn-one submit-btn mb-3 mr-3" @click="finalizarGrabacion()">
                                        <i class="fa fa-stop mr-2"></i>Finalizar Sesión<span></span>
                                    </button>
                                </div>
                                <div v-if="!es_paciente">
                                    <div>
                                        <button type="button" class="default-btn-one submit-btn mb-3 mr-3" @click="repartirTarjetas()">
                                            <i class="fa fa-arrows-rotate mr-2"></i>Repartir Cartas<span></span>
                                        </button>
                                    </div>
                                    <div>
                                        <button type="button" class="default-btn-one submit-btn mr-3" @click="siguienteTurno()">
                                            <i class="fa fa-arrow-alt-circle-right mr-2"></i>Siguiente turno<span></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div style="width: 100%;" class="d-inline row col-md-10">
                                <ul class="nav nav-tabs nav-justified" style="width:100%">
                                    <li class="nav-item" v-for="room_client in rooms_clients" :key="room_client.id">
                                        <div v-if="room_client.role == 'Paciente'">
                                            <a class="nav-link" @click.prevent="setActive(room_client.id)" :class="isActive(room_client.id) ? 'table-column bold' : ''" :style="room_client.turno ? 'color: green; font-weight: bold;' : ''">
                                                {{ room_client.name }} ({{ room_client.points}})
                                            </a>
                                            <a class="nav-link" v-if="!room_client.turno" @click.prevent="turnTo(room_client.cliente_id_hidden, room_client.id)" :class="isActive(room_client.id) ? 'table-column bold' : ''" >
                                                <u>Darle el turno</u>
                                            </a>
                                            <a class="nav-link" v-else :class="isActive(room_client.id) ? 'table-column bold' : ''" style="color: green;">
                                                (Tiene el turno)
                                            </a>
                                            <a v-if="isActive(room_client.id)" class="bold" :style="room_client.turno ? 'color: green' : ''">
                                                Viendo las cartas de {{ room_client.name }}:
                                            </a>
                                        </div>
                                    </li>
                                </ul>
                                <div class="row col-md-6" id="myTabContent" style="width:800px">
                                    <div style="height: 100%;" v-for="room_client in rooms_clients" :key="room_client.id">
                                        <div style="height: 100%; margin-right: 80px; padding-top: 28px"  v-if="room_client.role == 'Paciente' && activeItem == room_client.id">
                                            <div style="width: 100%; height: 100%;" class="row" :class="{ 'active show': isActive(room_client.id) }" :id="room_client.id">   
                                                <div style="width: 100%; padding: 1rem; display: flex; justify-content: space-between;"  :class="room_client_cards_data.size > 1 ? 'col-md-3' : 'col-md-3'" v-for="room_card in room_client_cards_data" :key="room_card.id" @click="seleccionarCarta(room_card)">
                                                    <div id="container-card" style="width: 470px; height: 130px; margin: -5px;  display: flex; justify-content: center;" class="card-body" :class="[room_card.card_id == selectCard ? 'border-blue' : '', room_card.seleccionado ? 'selected' : '']">
                                                        
                                                        <img  crossorigin="anonymous" :src="room_card.url" />
                                                    </div>
                                                </div>    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style="width: 100%; justify-content: center;" class="col-md-6" id="meet-container">
                                    <div style="width: 100%;" class="d-flex justify-content-center text-center">

                                        <div style="width: 100%; padding-right: 45px" id="meetingSDKElement" :key="componentKey"></div>
                                    </div>
                                </div>
                            </div>      
                            <!-- <div class="col-md-12">
                                <div class="d-flex justify-content-center text-center">
                                    
                                    <div id="meetingSDKElement" :key="componentKey"></div>
                                </div>
                            </div> -->
                        </div>

                        <div class="mb-5">
                            <h2>Historial</h2>
                            <history-table 
                                :history="history"
                            />
                        </div>
                    </div>
                </div>

            </div>
        </section>

        <div class="modal bg-black" tabindex="-1" v-if="modalTurnoOpened == true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">¡¡ Es tu turno !!</h5>
                    </div>
                    <div class="modal-body">
                        <div class="col-md-12">
                            <p>Puede seleccionar una tarjeta</p>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="default-btn submit-btn" @click="cerrarModalTurno()">Aceptar<span></span></button>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="modal bg-black" tabindex="-1" v-if="modalFinalizacionOpened == true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Finalizar sesión</h5>
                    </div>
                    <div class="modal-body">
                        <div class="col-md-12">
                            <p>¿Está seguro que deseas finalizar la sesión?</p>
                        </div>
                    </div>
                    <div class="modal-footer" style="display: flex; justify-content: space-evenly;">
                        <div class="row" style="width: 100%;">
                            <button type="button" style="width: 40%;" class="btn submit-btn" @click="cerrarModal()"><span>Cancelar</span></button>
                            <button type="button" style="width: 40%; margin-left: 5%;" class="default-btn submit-btn" @click="cerrarModalSesion()">Aceptar<span></span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        

        <div class="modal bg-black" tabindex="-1" v-if="modalEnviarOpened == true">
            <div class="modal-dialog modal-xl modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Datos del Usuario: {{ room_client_data.name }}</h5>
                        <a href="javascript:;" type="button" class="btn-close" @click="cerrarModalEnviar()"><i class="fa fa-times"></i></a>
                    </div>
                    <div class="modal-body">
                        
                        <div class="row">
                            <div class="col-md-9">
                                <div class="row">
                                    <div class="col-md-3 mb-3" v-for="room_card in room_client_cards_data" :key="room_card.id">
                                        <div class="card"> 
                                            <div class="card-body" :class="room_card.seleccionado && room_card.paciente != 0 ? 'selected' : ''">
                                                <img crossorigin="anonymous" :src="room_card.url" />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-12" v-if="room_client_cards_data.length == 0">
                                        <div class="text-center">Este usuario no posee cartas...</div>
                                    </div>

                                    <div class="col-md-12" :class="{ 'd-none': datosCargados == true }">
                                        <div class="text-center">Cargando listado...</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <h6 class="mb-2"><strong>Puntuacion:</strong> {{ room_client_data.points }}</h6>
                                <div class="mb-5">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <a href="javascript:;" class="mr-2" @click="restarPuntosUsuario(room_client_data.id)">
                                                <i class="fa fa-arrow-circle-down mr-2"></i>Restar
                                            </a>
                                        </div>

                                        <div class="col-md-6 text-right">
                                            <a href="javascript:;" class=""  @click="sumarPuntosUsuario(room_client_data.id)">
                                                <i class="fa fa-arrow-circle-up mr-2"></i>Sumar
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                
                                <h6 class="mb-3"><strong>Preguntar a:</strong></h6>
                                <div class="room-client">
                                    
                                    <a href="javascript:;" v-for="room_client in rooms_clients" :key="room_client.id" @click="seleccionarUsuarioParaEnviar(room_client.id)" :class="client_selected == room_client.id ? 'selected' : ''">
                                        <div v-if="room_client.role == 'Paciente' && room_client.id != room_client_data.id ">
                                            <div class="icon"><i class="fa fa-user"></i></div>
                                            <div class="name">{{ room_client.name }}</div>
                                        </div>
                                    </a>

                                </div>
                            </div>
                        </div>
                        <div class="form-group" style="display:none">                            
                            <select v-model="client_selected" name="client_selected" type="text" class="form-control" data-requerido="1">
                                <option value="">Seleccione un usuario</option>
                                <option v-for="room_client in rooms_clients" :key="room_client.id" :value="room_client.id">{{ room_client.name }}</option>
                            </select>
                        </div>

                        <div class="form-group" style="display:none">                            
                            <select v-model="card_seleccionada" name="card_seleccionada" type="text" class="form-control" data-requerido="1">
                                <option value="">Seleccione una carta</option>
                                <option v-for="room_card in room_client_cards_data" :key="room_card.id" :value="room_card.id">{{ room_card.id }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="default-btn-one submit-btn" data-bs-dismiss="modal" @click="cerrarModalEnviar()">Cerrar<span></span></button>
                        
                        <!--
                        <button type="button" class="default-btn submit-btn" @click="enviarTarjeta()">Enviar Carta<span></span></button>
                        -->

                        <button type="button" class="default-btn submit-btn" @click="emparentarTarjeta()">Preguntar por Carta<span></span></button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

    import axios from 'axios'
    
    import TitleSection from '@/components/TitleSection'
    import HistoryTable from '@/components/HistoryTable'
    
    import Utilities from '@/plugins/Utilities.js'

    import {endMeeting, getSignature } from '../utils/zoom-sdk-meeting'   
  
    import {WebSocketService} from '../utils/web-sockets';

    
    // eslint-disable-next-line 
    // let webSocketService;
    
    const MyAccountRoomsView = {
        name: 'MyAccountRoomsView',
        components: {
            TitleSection,
            HistoryTable
        },
        layout: 'sesion',
        data: function(){
            return {
                webSocketService:null,    
                componentKey:0,            
                datosCargados: true,
                mensajeExito: '',
                mensajeError: '',
                modalEnviarOpened: false,
                modalGrabacionOpened: true,
                modalPacientOpened: false,
                modalTurnoOpened: false,
                modalFinalizacionOpened: false,

                activeItem: undefined,

                rooms_cards: [],
                rooms_clients: [],
                history: [],

                room_client_data: [],
                room_client_cards_data: [],

                id: '',
                name: '',
                es_paciente: null,
                user_id: '',
                room_id: '',
                proxTurnoPaciente: -1,
                isCardSelected: false,
                card_id_seleccionado: -1,
                avisoTurnoPaciente: 0,
                pararInterval: '',
                yourTurn: false,
                firstEntry: true,

                carta_a_emparentar: undefined,
                card_seleccionada: '',
                client_selected: '',
                width_card: 150,
                width_container: 0,

                api: null,
                mediaRecorder: null,
                userRole:1,
                sessionStorageUser:{},
                reload: false,
                // para websockets
                roomParcipant:[],
                selectCard:'',
          

            }
        },
        async created() {

           await this.userSession()
           await this.consultarRoomsUsers()
            setTimeout(()=>{
                getSignature(this.$route.params?.id,this.userRole,this.sessionStorageUser.user_name, this.sessionStorageUser.user_email, document.getElementById('meetingSDKElement'),600,400)

            },3000)
            
        },
        mounted: async function(){
            // this.componentKey += 1
            const pageRoute = this.$route.name; 
            if (localStorage.getItem(`visited_${pageRoute}`)) {
                window.location.reload();
                localStorage.removeItem(`visited_${pageRoute}`);
            } else {
                localStorage.setItem(`visited_${pageRoute}`, "true");
            }
    
            this.reload=true

            setTimeout(async ()=>{

            this.webSocketService = await new WebSocketService(Utilities.getSocketUrl(),this.room_id,this.webClientId.id);
            this.webSocketService.registrarManejadorMensajes(this.manejarMensajeEntrante);
            },3000)
           
        },
        beforeMount: async function(){
            let _this = this
			if(!Utilities.isLogged()){
                this.$router.push({name:'login'})
			}
 
          await this.consultarData()
          await this.consultarRoomsCards()
          await this.consultarRoomsUsers()
          await this.consultarCartaSeleccionada()
 

            _this.interval = setInterval(function(){
             
                _this.consultarHistorial()
                _this.consultarCartaSeleccionada()
                _this.cardsUsers(_this.activeItem)
               
            }, 5000)

            this.pararInterval = setInterval(this.isYouTurn, 5000)
          if(this.webSocketService === null) {
            setTimeout(async () => {
                this.webSocketService = await new WebSocketService(Utilities.getSocketUrl(),this.room_id,this.webClientId.id);
                this.webSocketService.registrarManejadorMensajes(this.manejarMensajeEntrante);
            }, 5000);
          }else{
            this.webSocketService.registrarManejadorMensajes(this.manejarMensajeEntrante);
          }
		},
        beforeUnmount (){
            endMeeting()
        },
        destroyed (){
            endMeeting()
            this.webSocketService.cerrarConexion();
        },

        methods: {
            cerrarModalSesion(){
                this.modalFinalizacionOpened = false
                endMeeting()
                this.webSocketService.cerrarConexion();
                this.$router.push({name:'my-account-rooms', params: {id: this.id }})
            },
            cerrarModal(){
                this.modalFinalizacionOpened = false
            },
            isActive (menuItem) { 
                return this.activeItem == menuItem
            },
            setActive (menuItem) {
                this.cardsUsers(menuItem)
                this.activeItem = menuItem
            },
            consultarCartaSeleccionada(){
                let _this = this

                const formData = new FormData()
                formData.append('room_id', _this.room_id)

                axios.post(Utilities.getApiURL()  + 'cards/selected', formData)
                .then(response => {
                    _this.carta_a_emparentar = response.data.card_id;
                })
            },
            consultarHistorial(){
                let _this = this

                const formData = new FormData()
                formData.append('id', this.$route.params.id)

                axios.post(Utilities.getApiURL()  + 'history/room/get', formData)
                .then(response => {
                    _this.datosCargados = true

                    if(response.data.code == '1'){
                        _this.history = response.data.history
                        _this.history.forEach(
                            (line) => {
                                line.cuando = new Date(line.cuando)
                            }
                        )
                    }
                    else{
                        _this.history = []
                        _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                    }
                })
                .catch(error => {
                    _this.datosCargados = true
                    _this.mensajeError = error
                })

            },
            consultarData() {
                this.datosCargados = false
                this.mensajeExito = ''
                this.mensajeError = ''
                    
                let _this = this

                const formData = new FormData()
                formData.append('id', this.$route.params.id)

                axios.post(Utilities.getApiURL()  + 'rooms/get', formData)
                .then(response => {
                    _this.datosCargados = true

                    if(response.data.code == '1'){
                        _this.id = response.data.id
                        _this.name = response.data.name
                        _this.room_id = response.data.room_id
                    }
                    else{
                        _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                    }
                })
                .catch(error => {
                    _this.datosCargados = true
                    _this.mensajeError = error
                })
                
            },
            consultarRoomsCards(){
                this.datosCargados = false
                this.mensajeExito = ''
                this.mensajeError = ''
                    
                let _this = this
                this.mensajeError = ''

                const formData = new FormData()
                formData.append('id', this.$route.params.id)

                axios.post(Utilities.getApiURL()  + 'rooms-cards', formData)
                .then(response => {
                    _this.datosCargados = true

                    if(response.data.code == '1'){
                        _this.rooms_sets = response.data.rooms_sets
                        _this.width_container = (_this.width_card * _this.rooms_sets.length) + (20 * _this.rooms_sets.length)

          

                    }
                    else{
                        _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                    }
                })
                .catch(error => {
                    _this.datosCargados = true
                    _this.mensajeError = error
                })
                
            },
            consultarRoomsUsers(){
                this.datosCargados = false
                this.mensajeExito = ''
                this.mensajeError = ''
                    
                let _this = this
                this.mensajeError = ''

                const formData = new FormData()
                formData.append('id', this.$route.params.id)

                axios.post(Utilities.getApiURL()  + 'rooms-clients', formData)
                .then(response => {
                    _this.datosCargados = true

                    if(response.data.code == '1'){
                        _this.roomParcipant = response.data.rooms_clients
                        _this.getIdTerapeuta()
                        _this.rooms_clients = response.data.rooms_clients.filter(
                            (client) => client.role != 'Terapeuta'
                        )
                        if(!_this.activeItem){
                            this.setActive(_this.rooms_clients[0].id)
                        }
                 
                    }
                    else{
                        _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                    }
                })
                .catch(error => {
                    _this.datosCargados = true
                    _this.mensajeError = error
                })
                
            },
            siguienteTurno(){
                this.datosCargados = false
                this.mensajeExito = ''
                this.mensajeError = ''
                    
                let _this = this
                this.mensajeError = ''

                const formData = new FormData()
                formData.append('room_id', this.$route.params.id)

                axios.post(Utilities.getApiURL()  + 'rooms/next', formData)
                .then(() => {
                    this.consultarRoomsUsers()
                    _this.datosCargados = true
                })
                .catch(error => {
                    _this.datosCargados = true
                    _this.mensajeError = error
                })
            },
            turnTo(client_id_hidden, client_id){
                this.setActive(client_id)
                this.datosCargados = false
                this.mensajeExito = ''
                this.mensajeError = ''
                this.selectCard = ''
                let _this = this
                this.mensajeError = ''

                const formData = new FormData()
                formData.append('room_id', this.$route.params.id)
                formData.append('client_id_hidden', client_id_hidden)

                axios.post(Utilities.getApiURL()  + 'rooms/turn-to', formData)
                .then(() => {
                    _this.datosCargados = true
                    alert('turno generado correctamente')
                })
                .catch(error => {
                    _this.datosCargados = true
                    _this.mensajeError = error
                })
            },
            repartirTarjetas(){
                this.datosCargados = false
                this.mensajeExito = ''
                this.mensajeError = ''
                    
                let _this = this
                this.mensajeError = ''

                const formData = new FormData()
                formData.append('id', this.$route.params.id)

                axios.post(Utilities.getApiURL()  + 'rooms-clients/sort-cards', formData)
                .then(response => {
                    _this.datosCargados = true

                    if(response.data.code == '1'){
                        _this.mensajeExito = response.data.message
                    }
                    else{
                        _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                    }
                })
                .catch(error => {
                    _this.datosCargados = true
                    _this.mensajeError = error
                })
            },
            cardsUsers(room_client_id){
                this.datosCargados = false
                this.mensajeExito = ''
                this.mensajeError = ''
                    
                let _this = this

                const formData = new FormData()
                formData.append('id', room_client_id)

                axios.post(Utilities.getApiURL()  + 'rooms-clients/cards-user', formData)
                .then(response => {
                    _this.datosCargados = true

                    if(response.data.code == '1'){
                        _this.room_client_data = response.data.room_client_data
                        _this.room_client_cards_data = response.data.room_client_cards_data
                    }
                    else{
                        _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                    }
                })
                .catch(error => {
                    _this.datosCargados = true
                    _this.mensajeError = error
                })

            },
            cerrarModalTurno(){
                this.datosCargados = false
                this.mensajeExito = ''
                this.mensajeError = ''

                let _this = this            
                let userprox = this.getProxTurnoPaciente(_this.user_id)

                _this.datosCargados = false

                const formData = new FormData()
                formData.append('user_id', userprox)
                formData.append('user_last_id', _this.user_id)
                formData.append('room_id', _this.room_id)

                axios.post(Utilities.getApiURL()  + 'rooms-clients/turn/get', formData)
                .then(response => {
                    if(response.data.code == '1'){
                        _this.pararInterval = setInterval(_this.isYouTurn, 5000)
                        _this.modalTurnoOpened = false
                    }
                })
                .catch(error => {
                    _this.datosCargados = true
                    _this.mensajeError = error
                })
               

            },
            getProxTurnoPaciente(user_id){
                let i = 0
                let proxTurnoPaciente = 0
                let listaNueva = []
                if(this.rooms_clients != ''){
                    this.rooms_clients = this.rooms_clients.filter(el => { return el.role === "Paciente"})
                    this.rooms_clients.forEach(element => {
                        if(element.cliente == user_id){
                            if(i == this.rooms_clients.length-1){
                                proxTurnoPaciente = this.rooms_clients[0].cliente
                            }else{
                                proxTurnoPaciente = this.rooms_clients[i+1].cliente
                            }
                        }
                        i++
                    }) 
                    i = 0
                    this.rooms_clients.forEach(element => {
                        if(element.cliente == proxTurnoPaciente){
                            this.rooms_clients[i].turno = 1
                        }else{
                            this.rooms_clients[i].turno = 0
                        }
                        listaNueva.push(this.rooms_clients[i])
                        i++
                    })

                    if(listaNueva != null){
                        this.rooms_clients = null
                        this.rooms_clients = listaNueva    
                    }
                    
                    return proxTurnoPaciente
                    
                }
            },
            cerrarModalEnviar(){
                this.modalEnviarOpened = false
                this.modalPacientOpened = false
                this.card_seleccionada = ''
                this.client_selected = ''
            },
            seleccionarUsuarioParaEnviar(room_client_id){
                this.client_selected = room_client_id
            },
            seleccionarCarta(room_card) {
                this.card_seleccionada = room_card
                this.modalPacientOpened = true
            },
            async emparentarTarjetaSeleccionada(card_seleccionada){
                    if(this.carta_a_emparentar){
                        if(card_seleccionada.card_id == this.carta_a_emparentar){
                            //Carta seleccionada es PAR a carta_a_emparentar
                            const formData = new FormData()
                            formData.append('room_id', this.room_id)
                            formData.append('card_id', this.carta_a_emparentar)
                            formData.append('paciente_id', this.activeItem)

                            await axios.post(Utilities.getApiURL()  + 'cards/pair', formData)

                            this.message = "¡Acertaste! ¡Enhorabuena! Escoge otra carta"

                            formData.append('card_room_id', this.card_seleccionada.id)
                            
                            await axios.post(Utilities.getApiURL()  + 'history/room/pair', formData)
                        } else {
                            // Carta seleccionada no es PAR a carta_a_emparentar
                            const formData = new FormData()
                            formData.append('room_id', this.room_id)
                            formData.append('card_id', this.carta_a_emparentar)

                            await axios.post(Utilities.getApiURL()  + 'cards/unpair', formData)

                            this.message = "¡Casi aciertas!"

                            formData.append('card_room_id', this.card_seleccionada.id)
                            formData.append('paciente_id', this.user_id)
                            
                            await axios.post(Utilities.getApiURL()  + 'history/room/unpair', formData)
                        }

                        this.carta_a_emparentar = undefined
                    } else {
                        //Carta seleccionada a seleccionado = true y siguiente turno

                        const formData = new FormData()
                        formData.append('room_id', this.room_id)
                        formData.append('card_room_id', this.card_seleccionada.id)

                        await axios.post(Utilities.getApiURL()  + 'cards/select', formData)
                        this.message = undefined
                        
                        this.consultarData()
                        formData.append('paciente_id', this.user_id)
                        
                        await axios.post(Utilities.getApiURL()  + 'history/room/selected-card', formData)
                    }
                this.cerrarModalEnviar()
            },
            finalizarGrabacion(){
                this.modalFinalizacionOpened = true
            },
            isYouTurn(){ 
               this.consultarRoomsUsers()
               if(this.rooms_clients.length > 0){
                    this.rooms_clients.forEach(element => {
                        if(element.turno == 1 && element.cliente == this.user_id && element.role != 'Terapeuta'){
                            this.modalTurnoOpened = true
                            this.yourTurn = true
                            this.selectCard = ''   
                            clearInterval(this.pararInterval);
                        }
                    })
                    
                }  
            },

            userSession(){
              for (let i = 0; i < sessionStorage.length; i++) {
              const clave = sessionStorage.key(i);
              const valor = sessionStorage.getItem(clave);
              this.sessionStorageUser[clave] = valor;

              }

      
            },
            getIdTerapeuta(){
            const _this = this
    
            if(_this.roomParcipant.length > 0){
                _this.webClientId=_this.roomParcipant.find((prop)=> prop.name === this.sessionStorageUser.user_name )
            }
            
            },
            manejarMensajeEntrante(mensaje) { 
                const datos = JSON.parse(mensaje);
                
                if(!!datos && !!datos.card){
                    this.selectCard = datos.card   
                } else {
                    this.selectCard = ''
                } 
            }
        },

    }

    export default MyAccountRoomsView;
</script>

<style scoped>
    .debug  {
        border: 1px solid red;
    }

    .table tr:hover{
        cursor: pointer
    }

    .bg-black{
        background: rgba(0, 0, 0, 0.37)
    }

    .modal{
        display: initial;
    }

    .side-modal {
        position: fixed;
        top: 50%;
        left: 0;
        padding-left: 55px;
        transform: translateY(-50%);
        max-width: none;
    }

    @media (min-width: 1440px) {
        .side-modal {
            transform: translate(25%, -50%);
        }
    }
    
    @media (min-width: 1600px) {
        .side-modal {
            transform: translate(35%, -50%);
        }
    }
    
    @media (min-width: 1920px) {
        .side-modal {
            transform: translate(55%, -50%);
        }
    }

    .container-cards{
        overflow-x: hidden;        
    }
    
    .container-cards:hover{
        overflow-x: auto;        
    }

    .card{
        float: left;
        margin-right: 20px;
        cursor: pointer;
    }

    .card-header{
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    
    .card-body{
        margin: 3px;
        width: 100%;
        height: 70px;
        border: 3px solid #75072b;
    }

    .card-body:hover{
        border: 5px solid #757307;
    }
    .card-body.selected{
        border: 5px solid #077535;
    }
    
    .card-body img{
        width: 100px;
        height: auto;
        object-fit: contain;
    }

    .fa-arrows-rotate:before {
        content: "\f021";
    }

    .room-client{

    }

    .room-client a{
        
    }

    .room-client a > div{
        display: flex;
        padding: 0px 10px;
        border-radius: 10px;
    }

    .room-client a:hover{
        color: initial;
    }

    .room-client a:hover > div{
        background: #d5d5d5;
        color: initial;
    }

    .room-client a.selected > div{
        background: #ff0000;
    }

    .room-client a .icon{
        padding: 7px;
    }

    .room-client a .icon i{
        font-size: 20px;
    }

    .room-client a .name{
        padding: 7px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .room-client a .see{
        visibility: hidden;
        flex: 1;
        text-align: right;
        padding: 7px;
    }

    .room-client a:hover .see{
        visibility: visible;
    }

    .bold {
        font-weight: bold;
        font-size: 1em;
    }
    /* estilo del contenedor de la meet zoom */
    #meetingSDKElement {
    margin-top: 30px;
    display: flex;
    justify-content: center; 
    align-items: center; 
    height: 100vh; 
    position: relative;
    width: 592px; 
    height: 476px; 
    margin-left: 30px;
    /* border: 1px solid blue; */
    }

    #meet-container {
        margin: 10px;
    }
    .border-blue {
    border: 5px solid blue !important;
    }

</style>