<template>
    <div>
        <title-section></title-section>
        
        <section class="services-section section-padding">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="section-title">
                            <router-link to="/my-account">
                                <i class="fa fa-arrow-left mr-2"></i>Regresar
                            </router-link>
                            <h2>Seguridad</h2>
                        </div>
                    </div>
                </div>

                <div class="row align-items-center">
                    <div class="col-lg-4 offset-lg-4">
                        <div class="contact-form">
                            
                            <div v-if="mensajeError != ''" class="alert alert-danger mb-4" role="alert">
                                <i class="fa fa-exclamation-triangle"></i> <strong>Un momento.</strong> <span>{{ mensajeError }}</span>
                            </div>

                            <div v-if="mensajeExito != ''" class="alert alert-success mb-4" role="alert">
                                <i class="fa fa-check"></i> <strong>Excelente.</strong> <span>{{ mensajeExito }}</span>
                            </div>

                            <form class="contact-form form" @submit="realizarSubmit"> 
                                <div class="row">
                                    
                                    
                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label>Contraseña</label>
                                            <input v-model="password" name="password" type="password" class="form-control" data-requerido="1">
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label>Confirmar Contraseña</label>
                                            <input v-model="confirm_password" name="confirm_password" type="password" class="form-control" data-requerido="1">
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12 text-center mt-4 mb-4" :class="{ 'd-none': datosCargados == false }">
                                        <button type="submit" class="default-btn submit-btn"> <i class="fa fa-edit mr-2"></i>Cambiar Contraseña <span></span></button>
                                    </div>

                                    <div class="col-lg-12 col-md-12 text-center mb-4" :class="{ 'd-none': datosCargados == true }">
                                        <p>Cargando datos...</p>
                                    </div>

                                </div>
                            </form>

                        </div>
                    </div>
                </div>

            </div>
        </section>

    </div>
</template>

<script>
    import TitleSection from '@/components/TitleSection'
    import Utilities from '@/plugins/Utilities.js'
    import axios from 'axios'

    const MyAccountSecurity = {
        name: 'MyAccountSecurity',
        components: {
            TitleSection,
        },
        data: function(){
            return {                
                datosCargados: true,
                mensajeExito: '',
                mensajeError: '',
                
                password: '',
                confirm_password: '',
            }
        },
        methods: {
            realizarSubmit(e){
                e.preventDefault();
                
                this.datosCargados = false
                this.mensajeExito = ''
                this.mensajeError = ''

                if( this.password != '' && 
                    this.confirm_password != '' ){
                    
                    if( this.password == this.confirm_password  ){
                        let _this = this
                        this.mensajeError = ''

                        const formData = new FormData()
                        formData.append('id', sessionStorage.getItem("user_id"))
                        formData.append('password', this.password)

                        axios.post(Utilities.getApiURL()  + 'account/change-password', formData)
                        .then(response => {
                            _this.datosCargados = true

                            if(response.data.code == '1'){
                                _this.mensajeExito = response.data.message

                                _this.password = ''
                                _this.confirm_password = ''
                            }
                            else{
                                _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                            }
                        })
                        .catch(error => {
                            _this.datosCargados = true
                            _this.mensajeError = error
                        })
                    }
                    else{
                        this.datosCargados = true
                        this.mensajeError = "Las contraseñas ingresadas deben coincidir..."
                    }
                }
                else{
                    this.datosCargados = true
                    this.mensajeError = "Debes llenar todos los campos correctamente para poder cambiar su contraseña..."
                }
                
            }
        },
        beforeMount: function(){
			if(!Utilities.isLogged()){
                this.$router.push({name:'login'})
			}

		}
    }

    export default MyAccountSecurity;
</script>