<script>

//import HomePage from '@/pages/HomePage'
import LoginPage from '@/pages/LoginPage'
import RecoveryPage from '@/pages/RecoveryPage'
import LogoutPage from '@/pages/LogoutPage'
import MyAccountPage from '@/pages/MyAccountPage'
import MyAccountProfilePage from '@/pages/MyAccountProfilePage'
import MyAccountSecurityPage from '@/pages/MyAccountSecurityPage'
import MyAccountPacientsPage from '@/pages/MyAccountPacientsPage'
import MyAccountRoomsPage from '@/pages/MyAccountRoomsPage'
import MyAccountRoomsUsersPage from '@/pages/MyAccountRoomsUsersPage'
import MyAccountRoomsCardsPage from '@/pages/MyAccountRoomsCardsPage'
import MyAccountRoomsViewPage from '@/pages/MyAccountRoomsViewPage'
import MyAccountCardsPage from '@/pages/MyAccountCardsPage'
import MyAccountSetsPage from '@/pages/MyAccountSetsPage'
import MyAccountSetsCards from '@/pages/MyAccountSetsCards'
import MyAccountRoomsHistory from '@/pages/MyAccountRoomsHistory'
import PublicSessionPage from '@/pages/PublicSessionPage'
import NotFoundPage from '@/pages/NotFoundPage'


    
const routes = [
    // PARA LAS RUTAS DE LA PAGINA PRINCIPAL
    // { path: '/', name: 'home', component: HomePage}, se cambia para que la pagina de login sea la principal, ya que HomePage no se usa (renderiza un lorem ipsum con un boton que redirecciona al login)
    { path: '/', name: 'home', component: LoginPage},
    { path: '/login', name: 'login', component: LoginPage },
    { path: '/recovery', name: 'recovery', component: RecoveryPage },
    { path: '/logout', name: 'logout', component: LogoutPage },

    // PARA LAS CUENTAS DE LOS TERAPEUTAS
    { path: '/my-account', name: 'my-account', component: MyAccountPage },
    { path: '/my-account/profile', name: 'my-account-profile', component: MyAccountProfilePage },
    { path: '/my-account/security', name: 'my-account-security', component: MyAccountSecurityPage },    
    { path: '/my-account/pacients', name: 'my-account-pacients', component: MyAccountPacientsPage },
    { path: '/my-account/rooms', name: 'my-account-rooms', component: MyAccountRoomsPage },
    { path: '/my-account/sets', name: 'my-account-sets', component: MyAccountSetsPage },
    { path: '/my-account/rooms/users/:id', name: 'my-account-rooms-users', component: MyAccountRoomsUsersPage },
    { path: '/my-account/rooms/cards/:id', name: 'my-account-rooms-cards', component: MyAccountRoomsCardsPage },
    { path: '/my-account/rooms/view/:id', name: 'my-account-rooms-view', component: MyAccountRoomsViewPage },    
    { path: '/my-account/cards', name: 'my-account-cards', component: MyAccountCardsPage },
    { path: '/my-account/sets-cards/:id', name: 'my-account-sets-cards', component: MyAccountSetsCards },
    { path: '/my-account/rooms/history/:id', name: 'my-account-rooms-history', component: MyAccountRoomsHistory },

    // PARA LAS RUTAS DE LOS PACIENTES
    { path: '/session/:id', name: 'public-session', component: PublicSessionPage },

    { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFoundPage },
]

export default routes; 

</script>