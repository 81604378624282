class WebSocketService {
  count = 0;

  constructor(url, idRoom, idParcipant, client = null) {
    this.url = `${url}?idRoom=${idRoom}&id=${idParcipant}&client=${client}`;
    this.connect();
  }

  connect() {
    this.ws = new WebSocket(this.url);

    this.ws.onopen = () => {};

    this.ws.onmessage = (event) => {
      console.log('Mensaje recibido:', event.data);
    };

    this.ws.onclose = () => {
      console.log('Conexión WebSocket cerrada, intentando reconectar...');
      setTimeout(() => {
        this.reconnect();
      }, 5000);
    };

    this.ws.onerror = (error) => {
      console.error('Error en WebSocket:', error);
      this.ws.onclose();
    };
  }

  enviarMensaje(mensaje) {
    if (this.ws.readyState === WebSocket.OPEN) {
      this.ws.send(JSON.stringify(mensaje));
      console.log('Mensaje enviado:', mensaje);
    } else {
      this.ws.onerror();
    }
  }

  cerrarConexion() {
    if (this.ws) {
      this.ws.close();
    }
  }

  // callback para este metodo seria el metodo ne vue para manejar el estado y cambiarlo
  registrarManejadorMensajes(callback) {
    this.ws.onmessage = async (event) => {
      callback(event.data); // Llama al callback con el mensaje
    };
  }
  reconnect() {
    if (this.count > 3) {
      //alert('se ha detectado un error, por favor recarga la pagina');
      setTimeout(() => {
        this.count = 0;
        // this.reconnect();
        this.ws.onclose();
      }, 20000);
      return this.cerrarConexion();
    }

    this.count++;
    if (this.count < 3) {
      console.log('sumando', this.count);

      console.log('Reconectando al WebSocket...');
      this.connect();
    }
  }
}

export { WebSocketService };
