<template>
    <div>
        <title-section></title-section>
        
        <section class="services-section section-padding">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="section-title">
                            <router-link to="/my-account">
                                <i class="fa fa-arrow-left mr-2"></i>Regresar
                            </router-link>
                            <h2>Mis Datos</h2>
                        </div>
                    </div>
                </div>

                <div class="row align-items-center">
                    <div class="col-lg-4 offset-lg-4">
                        <div class="contact-form">
                            
                            <div v-if="mensajeError != ''" class="alert alert-danger mb-4" role="alert">
                                <i class="fa fa-exclamation-triangle"></i> <strong>Un momento.</strong> <span>{{ mensajeError }}</span>
                            </div>

                            <div v-if="mensajeExito != ''" class="alert alert-success mb-4" role="alert">
                                <i class="fa fa-check"></i> <strong>Excelente.</strong> <span>{{ mensajeExito }}</span>
                            </div>

                            <form class="contact-form form" @submit="realizarSubmit"> 
                                <div class="row">
                                    
                                    
                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label>Identificador</label>
                                            <input v-model="id_system" name="id_system" type="text" class="form-control" data-requerido="1">
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label>Nombre</label>
                                            <input v-model="first_name" name="first_name" type="text" class="form-control" data-requerido="1">
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label>Apelido</label>
                                            <input v-model="last_name" name="last_name" type="text" class="form-control" data-requerido="1">
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label>Correo Electrónico</label>
                                            <input v-model="email" name="email" type="text" class="form-control" data-requerido="1">
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12 text-center mt-4 mb-4" :class="{ 'd-none': datosCargados == false }">
                                        <button type="submit" class="default-btn submit-btn"> <i class="fa fa-edit mr-2"></i>Cambiar Datos<span></span></button>
                                    </div>

                                    <div class="col-lg-12 col-md-12 text-center mb-4" :class="{ 'd-none': datosCargados == true }">
                                        <p>Cargando datos...</p>
                                    </div>

                                </div>
                            </form>

                        </div>
                    </div>
                </div>

            </div>
        </section>

    </div>
</template>

<script>
    import TitleSection from '@/components/TitleSection'
    import Utilities from '@/plugins/Utilities.js'
    import axios from 'axios'

    const MyAccountProfile = {
        name: 'MyAccountProfile',
        components: {
            TitleSection,
        },
        data: function(){
            return {                
                datosCargados: true,
                mensajeExito: '',
                mensajeError: '',
                
                id_system: '',
                first_name: '',
                last_name: '',
                email: '',
            }
        },
        methods: {
            realizarSubmit(e){
                e.preventDefault();
                
                this.datosCargados = false
                this.mensajeExito = ''
                this.mensajeError = ''

                if( this.id_system != '' && 
                    this.first_name != '' &&
                    this.last_name != '' &&
                    this.email != '' ){
                    
                    let _this = this
                    this.mensajeError = ''

                    const formData = new FormData()
                    formData.append('id', sessionStorage.getItem("user_id"))
                    formData.append('id_system', this.id_system)
                    formData.append('first_name', this.first_name)
                    formData.append('last_name', this.last_name)
                    formData.append('email', this.email)

                    axios.post(Utilities.getApiURL()  + 'account/change-profile', formData)
                    .then(response => {
                        _this.datosCargados = true

                        if(response.data.code == '1'){
                            _this.mensajeExito = response.data.message
                        }
                        else{
                            _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                        }
                    })
                    .catch(error => {
                        _this.datosCargados = true
                        _this.mensajeError = error
                    })
                }
                else{
                    this.datosCargados = true
                    this.mensajeError = "Debes llenar todos los campos correctamente para poder cambiar su contraseña..."
                }
                
            },
            consultarData(){
                this.datosCargados = false
                this.mensajeExito = ''
                this.mensajeError = ''

                    
                let _this = this
                this.mensajeError = ''

                const formData = new FormData()
                formData.append('id', sessionStorage.getItem("user_id"))

                axios.post(Utilities.getApiURL()  + 'account/get-profile', formData)
                .then(response => {
                    _this.datosCargados = true

                    if(response.data.code == '1'){
                        _this.id_system = response.data.id_system
                        _this.first_name = response.data.first_name
                        _this.last_name = response.data.last_name
                        _this.email = response.data.email
                    }
                    else{
                        _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                    }
                })
                .catch(error => {
                    _this.datosCargados = true
                    _this.mensajeError = error
                })
                
            }
        },
        beforeMount: function(){
			if(!Utilities.isLogged()){
                this.$router.push({name:'login'})
			}

            this.consultarData()
		}
    }

    export default MyAccountProfile;
</script>