<template>
    <div>
        <title-section></title-section>
        
        <section class="services-section section-padding">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="section-title">
                            <h2>Mi Cuenta</h2>
                            <h6>Bienvenido {{ nombre }}</h6>                            
                        </div>
                    </div>
                </div>

                <div class="row">
                    
                    <div v-if="!paciente" class="col-lg-3 col-md-4 col-sm-4 col-6">
                        <router-link to="/my-account/pacients" class="single-services-item text-center">
                            <div class="services-icon">
                                <i class="fa fa-users"></i>
                            </div>
                            <h3>Pacientes</h3>
                        </router-link>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-4 col-6">
                        <router-link to="/my-account/rooms" class="single-services-item text-center">
                            <div class="services-icon">
                                <i class="fa fa-object-group"></i>
                            </div>
                            <h3>Grupos</h3>
                        </router-link>
                    </div>
                     <div class="col-lg-3 col-md-4 col-sm-4 col-6">
                        <router-link to="/my-account/cards" class="single-services-item text-center">
                            <div class="services-icon">
                                <i class="fa fa-th"></i>
                            </div>
                            <h3>Cartas</h3>
                        </router-link>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-4 col-6">
                        <router-link to="/my-account/profile" class="single-services-item text-center">
                            <div class="services-icon">
                                <i class="fa fa-user-cog"></i>
                            </div>
                            <h3>Mis Datos</h3>
                        </router-link>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-4 col-6">
                        <router-link to="/my-account/security" class="single-services-item text-center">
                            <div class="services-icon">
                                <i class="fa fa-lock"></i>
                            </div>
                            <h3>Seguridad</h3>
                        </router-link>
                    </div>
                        <div v-if="!paciente" class="col-lg-3 col-md-4 col-sm-4 col-6">
                        <router-link to="/my-account/sets" class="single-services-item text-center">
                            <div class="services-icon">
                                <i class="fa fa-layer-group"></i>
                            </div>
                            <h3>Sets de cartas</h3>
                        </router-link>
                    </div>

                </div>
            </div>
        </section>

    </div>
</template>

<script>
    import TitleSection from '@/components/TitleSection'
    import Utilities from '@/plugins/Utilities.js'

    const MyAccount = {
        name: 'MyAccount',
        components: {
            TitleSection,
        },
        data: function(){
            return {                
                nombre: '',
                paciente: false,
            }
        },
        beforeMount: function(){
			if(!Utilities.isLogged()){
                this.$router.push({name:'login'})
			}

            this.nombre = sessionStorage.getItem("user_name") 
            this.paciente = sessionStorage.getItem("paciente") === 'true' ? true : false     
		}

    }

    export default MyAccount;
</script>