import axios from 'axios';

import ZoomMtgEmbedded from '@zoomus/websdk/embedded';

import { URI_API_ZOOM, SDK_KEY, PASSWORD } from './config-zoom';

const client = ZoomMtgEmbedded.createClient();

async function getSignature(
  idMeet,
  role,
  userName,
  userEmail,
  meetingSDKElement,
  width,
  height
) {
  const getToken = async () => {
    const token = await axios.post(URI_API_ZOOM, {
      meetingNumber: +idMeet,
      role,
    });
    return token.data.signature;
  };

  const token = await getToken();
  startMeeting(
    token,
    idMeet,
    userName,
    userEmail,
    meetingSDKElement,
    width,
    height
  );
}

async function startMeeting(
  signature,
  idMeet,
  userName,
  userEmail,
  meetingSDKElement,
  width,
  height
) {
  await client.init({
    zoomAppRoot: meetingSDKElement,
    language: 'es-ES',
    maximumVideosInGalleryView: 7,
    customize: {
      meetingInfo: ['topic', 'host', 'invite', 'participant'],
      video: {
        isResizable: false,
        popper: {
          disableDraggable: true,
          anchorElement: meetingSDKElement,
        },
        defaultViewType: 'gallery',
        viewSizes: {
          default: {
            width,
            height,
          },
        },
      },
    },
  });
  await client.join({
    signature: signature,
    sdkKey: SDK_KEY,
    meetingNumber: +idMeet,
    password: PASSWORD,
    userName,
    userEmail,
  });

  client.on('connection-change', (payload) => {
    console.log(payload);
    if (payload.state === 'Closed') {
      console.log('evento cierre');
      return ZoomMtgEmbedded.destroyClient();
    }
  });
}

async function endMeeting() {
  await client.endMeeting();
  return await client.destroyClient();
}

async function leaveMeeting() {
  return await client.leaveMeeting();
}

export { getSignature, endMeeting, leaveMeeting };
