export default class Utilities {
  static isLogged = function () {
    // console.log('Checkeando si esta logueado');
    // return true;

    try {
      if (sessionStorage.getItem('user')) {
        if (
          sessionStorage.getItem('user_hash') ==
          this.generateHash(sessionStorage.getItem('user_id'))
        ) {
          return true;
        } else {
          sessionStorage.clear();
          return false;
        }
      }

      return false;
    } catch (e) {
      console.log(e);

      return false;
    }
  };

  static generateHash = function (s) {
    let texto = String(s);
    return texto.split('').reduce(function (a, b) {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);
  };

  static getApiURL = function () {
    //return 'https://blendarsys.ddns.net/api/api/v1/';
    // return 'http://localhost:8001/api/v1/';
    // return 'http://161.35.83.76:8101/api/v1/';
    // return 'https://ernguidelines.net:8043/api/v1/';
    // return 'http://localhost/api/v1/';
    // return 'http://igala.uma.es/api/v1/';
    return 'https://test2.akutalent.com/api/v1/';
    // return 'https://back-dev.akutalent.com/api/v1/';
  };

  static getURL = function () {
    return 'https://test1.akutalent.com/#/';
  };

  static getSocketUrl = function () {
    return 'wss://test2.akutalent.com/ws';
  };
}
