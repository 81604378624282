<template>
    <div>
        <title-section></title-section>
        
        <!-- Start Contact Section -->
        <div class="contact-section bg-grey section-padding">
            <div class="container">
                <div class="section-title">
                    <h1>Pagina no encontrada</h1>
                    <p>La pagina que intentas acceder no existe o no está disponible. Intenta verificar el enlace nuevamente...</p>
                </div>

                <div class="row">
                    <div class="col-md-12 text-center">
                        <router-link to="/">
                            <button type="submit" class="default-btn submit-btn">Ir al Inicio<span></span></button>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Contact Section -->

    </div>
</template>

<script>
    import TitleSection from '@/components/TitleSection'

    const NotFound = {
        name: 'NotFound',
        components: {
            TitleSection,
        },
        data: function(){
            return {
                datosCargados: true,
                mensajeExito: '',
                mensajeError: '',
            }
        },
        methods: {
            
        },
        beforeMount: function(){
			
		}
    }

    export default NotFound;
</script>