<template>
    <div>
        <title-section></title-section>
        
        <section class="services-section section-padding">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="section-title">
                            <router-link to="/my-account">
                                <i class="fa fa-arrow-left mr-2"></i>Regresar
                            </router-link>
                            <h2>Grupos</h2>
                        </div>
                    </div>
                </div>

                <div class="row align-items-center">
                    <div class="col-lg-12">
                        
                         <div v-if="mensajeError != ''" class="alert alert-danger mb-4" role="alert">
                            <i class="fa fa-exclamation-triangle"></i> <strong>Un momento.</strong> <span>{{ mensajeError }}</span>
                        </div>

                        <div v-if="mensajeExito != ''" class="alert alert-success mb-4" role="alert">
                            <i class="fa fa-check"></i> <strong>Excelente.</strong> <span>{{ mensajeExito }}</span>
                        </div>

                        <div v-if="!paciente" class="text-right mb-3">
                            <a href="javascript:;" @click="cambiarModoAgregarForm()">
                                <i class="fa fa-plus mr-2"></i>Nuevo Grupo
                            </a>
                        </div>

                        <div class="row">
                            
                            <div class="col-lg-5">

                                <div class="row">
                                    <div class="col-lg-12 col-md-12">
                                        <form class="contact-form form"> 
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <input v-model="search" name="search" type="text" class="form-control" placeholder="Buscar..." data-requerido="1">
                                                    <a href="javascript:;" class="input-group-text" @click='consultarGrupos'><i class="fa fa-search"></i></a>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    
                                    <div class="col-lg-12 col-md-12">   
                                        <table class="table table-striped table-hover">
                                            <tbody>
                                                <tr v-for="room in rooms" :key="room.id" @click="consultarData(room.id)" :class="{ 'd-none': datosListadoCargados == false }">
                                                    <td>{{ room.name }}</td>
                                                    <td>({{ room.room_clients_count }} Integrantes)</td>
                                                    <td class="text-right">
                                                        <i class="fa fa-edit"></i>
                                                    </td>
                                                </tr>

                                                <tr v-if="rooms.length == 0">
                                                    <td colspan="3">No hay resultados que coincidan con su busqueda...</td>
                                                </tr>

                                                <tr :class="{ 'd-none': datosListadoCargados == true }">
                                                    <td colspan="3">Cargando listado...</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                
                            </div>

                            <div class="col-lg-7">
                                <div class="card">
                                    <div class="card-body">
                                        <form class="contact-form form" @submit="realizarSubmit"> 
                                            <div class="row">
                                                
                                                
                                                <div class="col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <label>Nombre del Grupo</label>
                                                        <input v-model="name" name="name" type="text" class="form-control" data-requerido="1">
                                                    </div>
                                                </div>


                                                <div class="col-lg-12 col-md-12 text-center mt-4 mb-4" :class="{ 'd-none': datosCargados == false }">
                                                
                                                    <button type="submit" class="default-btn submit-btn" v-if="modo_form == 'agregar' && !paciente">
                                                        <i class="fa fa-plus mr-2"></i>Agregar Grupo<span></span>
                                                    </button>

                                                    <button type="submit" class="default-btn submit-btn mb-3 mr-3" v-if="modo_form == 'editar'  && !paciente">
                                                        <i class="fa fa-edit mr-2"></i>Aplicar/Cambiar nuevo nombre del grupo<span></span>
                                                    </button>
                                                    <button type="button" class="default-btn submit-btn mb-3 mr-3" @click="visualizarIntegrantes()" v-if="modo_form == 'editar'">
                                                        <i class="fa fa-users mr-2"></i>Elegir Integrantes<span></span>
                                                    </button>

                                                    <button type="button" class="default-btn submit-btn mb-3 mr-3" @click="visualizarTarjetas()" v-if="modo_form == 'editar'  && !paciente">
                                                        <i class="fa fa-th mr-2"></i>Ver Sets de cartas<span></span>
                                                    </button>

                                                    <button type="button" class="default-btn submit-btn mb-3 mr-3" @click="visualizarSesion()" v-if="modo_form == 'editar'">
                                                        <i class="fa fa-video mr-2"></i>Entrar en la Sesión<span></span>
                                                    </button>

                                                    <button type="button" class="default-btn submit-btn mb-3 mr-3" @click="verHistorial()" v-if="modo_form == 'editar'  && !paciente">
                                                        <i class="fa fa-th mr-2"></i>Ver Historial<span></span>
                                                    </button>

                                                    <div class="col-lg-12 col-md-12 text-center" v-if="modo_form == 'editar' && !paciente">
                                                        <a href="javascript:;" data-toggle="modal" @click="openModalDeleteGroup()"><i class="fa fa-times mr-2"></i>Eliminar Grupo</a>
                                                    </div>
                                                </div>

                                                <div class="col-lg-12 col-md-12 text-center mb-4" :class="{ 'd-none': datosCargados == true }">
                                                    <p>Cargando datos...</p>
                                                </div>

                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </div>
        </section>

        <div class="modal" tabindex="-1" v-if="modalRoomDeletedOpened == true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Eliminar grupo</h5>
                        <a href="javascript:;" type="button" class="btn-close" @click="cerrarModalDeleteGrupo()"><i class="fa fa-times"></i></a>
                    </div>
                    <div class="modal-body">
                        <div class="col-md-12">
                            <p>¿ Desea eliminar el grupo ?</p>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="default-btn submit-btn" data-bs-dismiss="modal" @click="eliminarGrupo()">Aceptar<span></span></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import TitleSection from '@/components/TitleSection'
    import Utilities from '@/plugins/Utilities.js'
    import axios from 'axios'
    import { URI_API_ZOOM_MEETINGS } from '../utils/config-zoom'

    const MyAccountRooms = {
        name: 'MyAccountRooms',
        components: {
            TitleSection,
        },
        data: function(){
            return {         
                modalRoomDeletedOpened: false,
                
                datosCargados: true,
                datosListadoCargados: true,
                modo_form: 'agregar',
                mensajeExito: '',
                mensajeError: '',

                search: '',
                rooms: [],

                id: '',
                name: '',
                paciente: false,
                tieneCartas: false,
            }
        },
        methods: {
            openModalDeleteGroup(){
                this.modalRoomDeletedOpened = true
            },
            cerrarModalDeleteGrupo(){
                this.modalRoomDeletedOpened = false
            },
            cambiarModoAgregarForm(){
                this.id = ''
                this.name = ''

                this.modo_form = 'agregar'
            },
            consultarGrupos(){
                this.datosListadoCargados = false
                this.mensajeExito = ''
                this.mensajeError = ''

                    
                let _this = this
                this.mensajeError = ''

                const formData = new FormData()
                formData.append('search', this.search)

                axios.post(Utilities.getApiURL()  + 'rooms', formData)
                .then(response => {
                    _this.datosListadoCargados = true

                    if(response.data.code == '1'){
                        _this.rooms = response.data.rooms
                    }
                    else{
                        _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                    }
                })
                .catch(error => {
                    _this.datosListadoCargados = true
                    _this.mensajeError = error
                })
                
            },
            consultarData(id){
                this.datosCargados = false
                this.mensajeExito = ''
                this.mensajeError = ''
                    
                let _this = this
                this.mensajeError = ''

                const formData = new FormData()
                formData.append('id', id)

                axios.post(Utilities.getApiURL()  + 'rooms/get', formData)
                .then(response => {
                    _this.datosCargados = true

                    if(response.data.code == '1'){
                        _this.id = response.data.id
                        _this.name = response.data.name

                        _this.modo_form = 'editar'
                    }
                    else{
                        _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                    }
                })
                .catch(error => {
                    _this.datosCargados = true
                    _this.mensajeError = error
                })
                
            },
            async realizarSubmit(e){
                e.preventDefault();
             //  id user zoom, se obtiene desde la api "server-to-server-"
            
                const uriServerExpress = URI_API_ZOOM_MEETINGS
                this.datosCargados = false
                this.mensajeExito = ''
                this.mensajeError = ''

                if( this.name != '' ){
                    // crear la zoom meet
                    const createMeetingData = {
                        topic: this.name,
                        type: '2',
                        start_time: new Date(),
                        duration: 240,
                        timezone: 'Europe/Madrid',
                        password: '123',
                        agenda:  this.name,
                        meeting_authentication: 'false',
                        settings: {
                            host_video: 'true',
                            participant_video: 'true',
                            mute_upon_entry: 'false',
                    
                        },
                    };

                    const createMeetingZoom = await axios.post(uriServerExpress, createMeetingData)


                    
                    // zoom 

                    let _this = this
                    this.mensajeError = ''

                    let url_modo = ''
                    if(this.modo_form == 'agregar'){
                        url_modo = 'add'
                    }
                    else{
                        url_modo = 'edit'
                    }

                    const formData = new FormData()
                    // se envia al backend el id de la meet de zoom creado desde la api
                    formData.append('id', createMeetingZoom.data.id)
                    // formData.append('id', this.id)
                    formData.append('name', this.name)
                    


                    axios.post(Utilities.getApiURL()  + 'rooms/' + url_modo, formData)
                    .then(response => {
                        _this.datosCargados = true

                        if(response.data.code == '1'){
                            _this.search = ''
                            _this.consultarGrupos()

                            _this.id = ''
                            _this.name = ''

                            _this.modo_form = 'agregar'

                            setTimeout(function(){
                                _this.mensajeExito = response.data.message
                            }, 1000)
                        }
                        else{
                            _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                        }
                    })
                    .catch(error => {
                        _this.datosCargados = true
                        _this.mensajeError = error
                    })
                }
                else{
                    this.datosCargados = true
                    this.mensajeError = "Debes rellenar el nombre del grupo"
                }
                
            },
            eliminarGrupo(){
                this.datosCargados = false
                this.mensajeExito = ''
                this.mensajeError = ''
                    
                let _this = this
                this.mensajeError = ''

                const formData = new FormData()
                formData.append('id', this.id)

                axios.post(Utilities.getApiURL()  + 'rooms/delete', formData)
                .then(response => {
                    _this.datosCargados = true

                    if(response.data.code == '1'){
                         _this.search = ''
                         _this.modalRoomDeletedOpened = false
                        _this.consultarGrupos()

                        _this.id = ''
                        _this.name = ''

                        _this.modo_form = 'agregar'

                        setTimeout(function(){
                            _this.mensajeExito = response.data.message
                        }, 1000)
                    }
                    else{
                        _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                        _this.modalRoomDeletedOpened = false
                    }
                })
                .catch(error => {
                    _this.datosCargados = true
                    _this.mensajeError = error
                })
                
            },
            visualizarIntegrantes(){
                this.$router.push({name:'my-account-rooms-users', params: {id: this.id }})
            },
            visualizarTarjetas(){
                this.$router.push({name:'my-account-rooms-cards', params: {id: this.id }})
            },
            verHistorial(){
                this.$router.push({name:'my-account-rooms-history', params: {id: this.id }})
            },
            visualizarSesion(){           
                this.datosCargados = false
                this.mensajeExito = ''
                this.mensajeError = ''
                let _this = this

                const formData = new FormData()
                formData.append('id', sessionStorage.getItem("id_user"))
                formData.append('room_id', this.id)

                axios.post(Utilities.getApiURL() + 'rooms-clients/cards', formData)
                .then(response => {
                    _this.datosCargados = true

                    if(response.data.code == '1'){
                        if(!response.data.has_set){
                            this.mensajeError = 'No existen sets en la sesión'
                        }else{
                            this.$router.push({name:'my-account-rooms-view', params: {id: this.id }})
                        }
                    }
                    else{
                        _this.mensajeError = ('La sesión no te tiene como terapeuta autorizado o no tiene terapeutas/pacientes')
                    }
                })
                .catch(error => {
                    _this.datosCargados = true
                    _this.mensajeError = error
                })
                
            }
        },
        watch: {
            search: function(new_value){
                if(new_value.length >= 3 || new_value.length == 0){
                    this.consultarGrupos()
                }
            }
        },
        beforeMount: function(){
			if(!Utilities.isLogged()){
                this.$router.push({name:'login'})
			}

            this.consultarGrupos()
            this.paciente = sessionStorage.getItem("paciente") === 'true' ? true : false
		}
    }

    export default MyAccountRooms;
</script>

<style scoped>
    .table tr:hover{
        cursor: pointer
    }
     .modal{
        display: initial;
    }
</style>