<template>
  <div class="global-container" id="app">
    <layout ref="layout">
      <router-view @refreshLogued="refreshLogued" class="view"></router-view>
    </layout>
  </div>
</template>

<script>
  import Layout from '@/components/Layout'

  const App = {
    name: 'App',
    components: {
      Layout,
    },
    methods: {
        refreshLogued(){
          this.$refs.layout.refreshLogued()
        }
    },
    computed: {
      // layout(){
      //   return this.$route.matched[0].components.default.layout || ''
      // }
    }
  }

  export default App

</script>

<style>
.global-container {
  max-width: 1920px;
  width: 100%;
}
</style>
