<template>
    <div>
        <div :class="{ 'd-none':pagina_cargada == false }">
            <div :class="{ 'd-none':url_encontrada == false }">

                <section class="services-section">
                    <div class="">
                        <div class="row align-items-center">
                            <div class="col-lg-12">
                                
                                <div v-if="mensajeError != ''" class="alert alert-danger mb-4" role="alert">
                                    <i class="fa fa-exclamation-triangle"></i> <strong>Un momento.</strong> <span>{{ mensajeError }}</span>
                                </div>

                                <div v-if="mensajeExito != ''" class="alert alert-success mb-4" role="alert">
                                    <i class="fa fa-check"></i> <strong>Excelente.</strong> <span>{{ mensajeExito }}</span>
                                </div>

                                <div>
                                    <div class="col-sm-12">
                                        <div class="section-title" v-if="enhorabuena">
                                            <h2 style="color: green;">¡Enhorabuena {{ userName }} has ganado!</h2>
                                        </div>
                                        <div v-else class="section-title">
                                            <h2>{{ userName }}</h2>
                                            <h4 v-if="!yourTurn" style="color: red;">No es tu turno</h4>
                                            <h4 v-if="yourTurn" style="color: green; font-size: 3em;">¡Es tu turno!</h4>
                                            <h4 v-show="message"  style="color: #1B806A; font-size: 2.5rem; font-weight: 700; min-height:0.75em;">{{ message }}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div style="width: 1280px;" class="row">
                                    
                                    <div style="width: 100%; padding-left: 55px;" class="col-md-6">
                                                    

                                        <h3 style="text-align:center;"><strong>Puntos:</strong> {{ client_points }}</h3>
                                        
                                        <div class="row" style="width: 100%;">
                                            <div class="col-md-3" v-for="room_card in client_cards" :key="room_card.id" @click="seleccionarCarta(room_card)" style="padding:0.5rem;" >
                                                <div class="card-body" :class="room_card.id == selectedCard ? 'border-blue' : ''">
                                                    <div :class="room_card.seleccionado && room_card.paciente != 0 ? 'selected' : ''">
                                                        <img crossorigin="anonymous" :src="room_card.url" />
                                                    </div>
                                                </div>
                                            </div> 
                                            
                                        </div>
                                        <div class="row" style="width: 100%;">
                                            <div class="col-md-12" v-if="rooms_cards.length == 0">
                                                <div class="text-center">No posees cartas actualmente...</div>
                                            </div>
                                            <!-- <div class="col-md-12" :class="{ 'd-none': datosCargados == true }">
                                                <div class="text-center">Cargando listado...</div>
                                            </div> -->
                                        </div>
                                        <div class="col-md-12" :class="{ 'd-none': datosCargados == true }">
                                            <div class="text-center">Cargando listado...</div>
                                        </div>
                                    </div>

                                    <div style="width: 100%;" class="col-md-6  ">
                                        <div style="width: 100%;" class=" ">
                                            <div style="width: 100%;" class=" " id="meetingSDKElement"></div>
                                        </div>
                                    </div>
                                    
                                </div>
                                
                            </div>
                        </div>

                    </div>
                </section>

            </div>

            <div :class="{ 'd-none':url_encontrada == true }">

                <section class="services-section section-padding">
                    <div class="">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="section-title">
                                    <h2>Sesión no encontrada</h2>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-12 text-center">
                                
                                <p>El enlace que intentas acceder es errónea. Contacta a tu terapeuta si tienes problemas</p>

                            </div>
                        </div>

                    </div>
                </section>

            </div>
        </div>

        <div  class="modal bg-black" tabindex="-1" v-if="modalPacientOpened">
    <div class="modal-dialog modal-mg side-modal">
        <div class="modal-content">
            <div class="modal-header">
                <a href="javascript:;" type="button" class="btn-close" @click="cerrarModalEnviar()"><i class="fa fa-times"></i></a>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div style="width: 100%; height: 100%; margin-right: 0" class="card">
                        <div style="width: 100%; height: 100%; border: none; max-height: 320px;" class="card-body">
                            <img style="width: 100%; height: 100%; max-height: 300px;" crossorigin="anonymous" :src="card_seleccionada.url" />
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="yourTurn" class="modal-footer" style="display: flex; justify-content: space-evenly;">
                <div class="row justify-content-center" style="width: 80%;">
                    <button type="button" style="margin-top:0px; margin-left: 5%;" class="default-btn-one submit-btn" @click="emparentarTarjetaSeleccionada(card_seleccionada)">👍<span></span></button>
                    <button type="button" style="margin-top:0px; margin-left: 5%;" class="default-btn-one submit-btn" data-bs-dismiss="modal" @click="cerrarModalEnviar()">👎<span></span></button>
                </div>
            </div>
        </div>
    </div>
</div>


        <div class="modal bg-black" tabindex="-1" v-if="modalEnviarOpened == true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Enviar Tarjeta</h5>
                        <a href="javascript:;" type="button" class="btn-close" @click="cerrarModalEnviar()"><i class="fa fa-times"></i></a>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label>¿A quién deseas enviarle la tarjeta?</label>
                            <select v-model="client_selected" name="client_selected" type="text" class="form-control" data-requerido="1">
                                <option value="">Seleccione un usuario</option>
                                <option v-for="room_client in rooms_clients" :key="room_client.id" :value="room_client.id">{{ room_client.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="default-btn-one submit-btn" data-bs-dismiss="modal" @click="cerrarModalEnviar()">Cerrar<span></span></button>
                        <button type="button" class="default-btn submit-btn" @click="enviarTarjeta()">Enviar Tarjeta<span></span></button>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
    import TitleSection from '@/components/TitleSection'
    import Utilities from '@/plugins/Utilities.js'
    import axios from 'axios'

    import { getSignature, endMeeting } from '../utils/zoom-sdk-meeting'  
    import { WebSocketService } from '../utils/web-sockets';
        
        
    const MyAccountRoomsView = {
        name: 'MyAccountRoomsView',
        components: {
            TitleSection,
        },
        async created() {
       
            this.consultarData()
     
            this.consultarRoomsUsers()

        },
        data: function(){
            return {
                userRole:0,                
                datosCargados: true,
                mensajeExito: '',
                mensajeError: '',
                modalEnviarOpened: false,
                url_encontrada: false,
                pagina_cargada: false,
                modalPacientOpened: false,
                consultarTarjeta: true,

                rooms_cards: [],
                rooms_clients: [],

                id: '',
                name: '',
                userName: '',
                yourTurn: false,
                user_id: null,

                card_seleccionada: '',
                client_selected: '',
                width_card: 150,
                width_container: 0,

                client_points: '',
                client_cards: [],

                carta_a_emparentar: undefined,

                api: null,
                message: undefined,

                enhorabuena: false,
                interval: undefined,
                // para webspcket
                webSocketService: null,
                roomParcipant: [],
                // para mantener resaltada la seleccion
                selectedCard: ''
            }
        },
        methods: {
            consultarData() {
                this.datosCargados = false
                this.mensajeExito = ''
                this.mensajeError = ''
                    
                let _this = this
                this.mensajeError = ''

                const formData = new FormData()
                formData.append('id', this.$route.params.id)

                axios.post(Utilities.getApiURL()  + 'rooms/session', formData)
                .then(response => {
                    _this.datosCargados = true

                    if(response.data.code == '1'){
                        _this.id = response.data.id
                        _this.user_id = response.data.user_id
                        _this.room_id = response.data.room_id
                        _this.name = response.data.name
                        _this.userName = response.data.user_name
                        _this.client_cards = response.data.cards
                        _this.url_encontrada = true
                        if(_this.client_cards.length == 0 && response.data.points > 0){
                            _this.enhorabuena = true
                            clearInterval(_this.interval)
                        } else {
                            _this.modalTurnoOpened = response.data.turno == 1
                            if(_this.modalTurnoOpened){
                                _this.consultarTarjeta = true
                            } else {
                                _this.yourTurn = false
                                //se modifica asignacion de estado de mensaje para mantener el mensaje,
                                // el siguente turno 
                                // _this.message = ''
                            }
                        }

                        if(_this.client_points < response.data.points){
                            _this.message = "¡Enhorabuena! ¡Has ganado 1 punto!"
                        }
                        _this.client_points = response.data.points
                    }
                    else{
                       
                        _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                    }
                })
                .catch(error => {
                    _this.datosCargados = true
                    _this.mensajeError = error
                })

                if(this.modalTurnoOpened && this.consultarTarjeta){
                    this.consultarTarjeta = false;

                    const formData = new FormData()
                    formData.append('room_id', _this.room_id)

                    axios.post(Utilities.getApiURL()  + 'cards/selected', formData)
                    .then(response => {
                        _this.yourTurn = _this.modalTurnoOpened
                        if(response.data.card_id){
                            _this.message = "Selecciona una carta"
                        }
                        _this.carta_a_emparentar = response.data.card_id;
                    })
                }
                
            },
            async emparentarTarjetaSeleccionada(card_seleccionada){
                if(this.yourTurn){
                    this.selectedCard = card_seleccionada.id
                    if(this.carta_a_emparentar){
                        if(card_seleccionada.card_id == this.carta_a_emparentar){
                            //Carta seleccionada es PAR a carta_a_emparentar
                            const formData = new FormData()
                            formData.append('room_id', this.room_id)
                            formData.append('card_id', this.carta_a_emparentar)
                            formData.append('paciente_id', this.user_id)

                            await axios.post(Utilities.getApiURL()  + 'cards/pair', formData)

                            this.message = "¡Acertaste! ¡Enhorabuena! Escoge otra carta"

                            formData.append('card_room_id', this.card_seleccionada.id)
                            
                            await axios.post(Utilities.getApiURL()  + 'history/room/pair', formData)
                        } else {
                            // Carta seleccionada no es PAR a carta_a_emparentar
                            const formData = new FormData()
                            formData.append('room_id', this.room_id)
                            formData.append('card_id', this.carta_a_emparentar)

                            await axios.post(Utilities.getApiURL()  + 'cards/unpair', formData)

                            this.message = "¡Casi aciertas!"

                            formData.append('card_room_id', this.card_seleccionada.id)
                            formData.append('paciente_id', this.user_id)
                            
                            await axios.post(Utilities.getApiURL()  + 'history/room/unpair', formData)
                        }

                        this.carta_a_emparentar = undefined
                    } else {
                        //Carta seleccionada a seleccionado = true y siguiente turno

                        const formData = new FormData()
                        formData.append('room_id', this.room_id)
                        formData.append('card_room_id', this.card_seleccionada.id)

                        await axios.post(Utilities.getApiURL()  + 'cards/select', formData)
                        this.message = undefined
                        
                        this.consultarData()
                        formData.append('paciente_id', this.user_id)
                        
                        await axios.post(Utilities.getApiURL()  + 'history/room/selected-card', formData)
                    }
                }
                this.cerrarModalEnviar()
            },
            getTarjetasPaciente(user_id=null) {
                this.datosCargados = false
                this.mensajeExito = ''
                this.mensajeError = ''

                let _this = this

                const formData = new FormData()
                if(user_id != null){
                    formData.append('id', user_id)
                }else{
                    formData.append('id', _this.user_id)
                }
                formData.append('room_id', _this.room_id)

                axios.post(Utilities.getApiURL() + 'rooms-clients/get-cards', formData)
                .then(response => {
                    _this.datosCargados = true

                    if(response.data.code == '1'){
                        _this.room_client_cards_data = response.data.room_client_cards_json
                    }
                    else{
                        _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                    }
                })
                .catch(error => {
                    _this.datosCargados = true
                    _this.mensajeError = error
                })
            },
            seleccionarCarta(room_card) {
            
                const msg = {
                    card: room_card.card_id,
                    user: this.user_id
                } 
                
                if(this.yourTurn){
                    this.webSocketService.enviarMensaje(JSON.stringify(msg))
                }

                this.card_seleccionada = room_card
                this.modalPacientOpened = true
                

            },
           consultarRoomsCards(){
                this.datosCargados = false
                this.mensajeExito = ''
                this.mensajeError = ''
                    
                let _this = this
                this.mensajeError = ''

                const formData = new FormData()
                formData.append('id', this.$route.params.id)

                axios.post(Utilities.getApiURL()  + 'rooms-cards/session', formData)
                .then(response => {
                    _this.datosCargados = true
                    _this.pagina_cargada = true

                    if(response.data.code == '1'){
                        _this.rooms_cards = response.data.rooms_cards
                        _this.width_container = (_this.width_card * _this.rooms_cards.length) + (20 * _this.rooms_cards.length)
                        

                        if(this.api != null){
                            console.log("Loaded Good")
                        }
                        
                        _this.url_encontrada = true

                    }
                    else{
                        _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                        _this.url_encontrada = false
                    }
                })
                .catch(error => {
                    _this.datosCargados = true
                    _this.mensajeError = error

                    _this.pagina_cargada = true
                    _this.url_encontrada = false

                })
                
            },
            consultarRoomsUsers(){
                this.datosCargados = false
                this.mensajeExito = ''
                this.mensajeError = ''
                    
                let _this = this
                this.mensajeError = ''

                const formData = new FormData()
                formData.append('id', this.$route.params.id)

                axios.post(Utilities.getApiURL()  + 'rooms-clients/session', formData)
                .then(response => {
                    _this.datosCargados = true

                    if(response.data.code == '1'){
                        _this.rooms_clients = response.data.listado_pacientes
                    }
                    else{
                        _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                    }
                })
                .catch(error => {
                    _this.datosCargados = true
                    _this.mensajeError = error
                })
                
            },
            abrirModalEnviar(id_card){
                this.modalEnviarOpened = true
                this.card_seleccionada = id_card                
            },
            cerrarModalEnviar(){
                this.modalEnviarOpened = false
                this.modalPacientOpened = false
                // para mantener la seleccion de carta
               // this.card_seleccionada = ''
                this.client_selected = ''
            },
            enviarTarjeta(){
                if(this.client_selected != ''){
                    const name_client = 'Temporal'
                    alert('Enviando la tarjeta al paciente ' + name_client + ' ;)');
                }
                else{
                    alert("Debe seleccionar un paciente para poder enviar la tarjeta...");
                }
            },

        },
        watch:{
            yourTurn : function(newValue){
                if(newValue === true){
                    this.selectedCard = ''

                    if ( this.message && !this.message.includes('Enhorabuena')) {
                        this.message = ''
                    }
                }

                if(newValue === false && this.message && !this.message.includes('Enhorabuena') ){
                      this.message = ''
                }
            },
           
        },
        beforeMount: function(){
            
            let _this = this
            _this.consultarData()
            _this.consultarRoomsCards()
            _this.consultarRoomsUsers()

            
            _this.interval = setInterval(function(){
                _this.consultarData()
            }, 5000)
            
		},
        mounted: function(){   
            const pageRoute = this.$route.name; 
            if (localStorage.getItem(`visited_${pageRoute}`)) {
                window.location.reload();
                localStorage.removeItem(`visited_${pageRoute}`);
            } else {
                localStorage.setItem(`visited_${pageRoute}`, "true");
            }
            
            setTimeout(()=>{
                
                getSignature(this.id,this.userRole,this.userName, '', document.getElementById('meetingSDKElement'),600,400)
                 this.webSocketService = new WebSocketService(Utilities.getSocketUrl(),this.room_id,null,this.user_id);
              
            },3000)      
        },
        destroyed (){
             endMeeting()
             this.webSocketService.cerrarConexion()
        },


    }
  

    export default MyAccountRoomsView;
</script>

<style scoped>
    .debug {
        border: 1px solid red;
    }

    .side-modal {
        position: fixed;
        top: 50%;
        left: 0;
        padding-left: 55px;
        transform: translateY(-50%);
        max-width: none;
    }

    @media (min-width: 1440px) {
        .side-modal {
            transform: translate(20%, -50%);
        }
    }
    
    @media (min-width: 1600px) {
        .side-modal {
            transform: translate(30%, -50%);
        }
    }
    
    @media (min-width: 1920px) {
        .side-modal {
            transform: translate(50%, -50%);
        }
    }

    .table tr:hover{
        cursor: pointer
    }

    .bg-black{
        background: rgba(0, 0, 0, 0.37)
    }

    .modal{
        display: initial;
    }

    .container-cards{
        overflow-x: hidden;        
    }
    
    .container-cards:hover{
        overflow-x: auto;        
    }

    .card{
        float: left;
        margin-right: 20px;
        cursor: pointer;
    }

    .card-header{
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    
    .card-body{
        width: 100%;
        border: 6px solid #75072b;
        cursor: pointer;
        margin: 1px;
        border-radius: 5px;
    }

    .card-body:hover,
    .card-body.selected{
        border: 1px solid black;
        margin: 0px;
    }
    
    .card-body img{
        width: 100%;
        height: 70px;
        object-fit: contain;
    }

    #meetingSDKElement {
    margin-top: 30px;
    display: flex;
    justify-content: center; 
    align-items: center; 
    height: 100vh; 
    position: relative;
    width: 592px; 
    height: 476px; 
    }
    .border-blue {
    border: 5px solid blue !important;
    }
</style>