<template>
    <div>
        <title-section></title-section>
        
        <!-- Start Contact Section -->
        <div class="contact-section bg-grey section-padding">
            <div class="container">
                <div class="section-title">
                    <h1>Recuperar Contraseña</h1>
                </div>

                <div class="row align-items-center">
                    <div class="col-lg-4 offset-lg-4">
                        <div class="contact-form">
                            
                            <div v-if="mensajeError != ''" class="alert alert-danger mb-4" role="alert">
                                <i class="fa fa-exclamation-triangle"></i> <strong>Un momento.</strong> <span>{{ mensajeError }}</span>
                            </div>

                            <div v-if="mensajeExito != ''" class="alert alert-success mb-4" role="alert">
                                <i class="fa fa-check"></i> <strong>Excelente.</strong> <span>{{ mensajeExito }}</span>
                            </div>

                            <form class="contact-form form" @submit="realizarSubmit"> 
                                <div class="row">
                                    
                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label>Correo Electrónico</label>
                                            <input v-model="email" name="email" type="text" class="form-control" data-requerido="1">
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12 text-center mt-4 mb-4" :class="{ 'd-none': datosCargados == false }">
                                        <button type="submit" class="default-btn submit-btn">Recuperar Contraseña <span></span></button>
                                    </div>

                                    <div class="col-lg-12 col-md-12 text-center mb-4" :class="{ 'd-none': datosCargados == true }">
                                        <p>Cargando datos...</p>
                                    </div>

                                    <div class="col-lg-12 col-md-12 text-center">
                                        <router-link to="/login">Iniciar Sesión</router-link>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Contact Section -->

    </div>
</template>

<script>
    import TitleSection from '@/components/TitleSection'
    import Utilities from '@/plugins/Utilities.js'
    import axios from 'axios'

    const Login = {
        name: 'Login',
        components: {
            TitleSection,
        },
        data: function(){
            return {
                datosCargados: true,
                mensajeExito: '',
                mensajeError: '',
                
                email: '',
            }
        },
        methods: {
            realizarSubmit(e){
                e.preventDefault();
                
                this.datosCargados = false
                this.mensajeExito = ''
                this.mensajeError = ''

                if( this.email != ''){
                    

                    
                    let _this = this
                    this.mensajeError = ''

                    const formData = new FormData()
                    formData.append('email', this.email)

                    axios.post(Utilities.getApiURL()  + 'recovery', formData)
                    .then(response => {
                        _this.datosCargados = true

                        if(response.data.code == '1'){
                            _this.mensajeExito = response.data.message

                            _this.email = ''
                        }
                        else{
                            _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                        }
                    })
                    .catch(error => {
                        _this.datosCargados = true
                        _this.mensajeError = error
                    })

                }
                else{
                    this.datosCargados = true
                    this.mensajeError = "Debes llenar todos los campos correctamente para poder recuperar su contraseña..."
                }
                
            }
        },
        beforeMount: function(){
			if(Utilities.isLogged()){
                this.$router.push({name:'my-account'})
			}
		}
    }

    export default Login;
</script>