<template>
    <div>
        <title-section></title-section>
        
        <section class="services-section section-padding">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="section-title">
                            <router-link to="/my-account">
                                <i class="fa fa-arrow-left mr-2"></i>Regresar
                            </router-link>
                            <h2>Cartas</h2>
                        </div>
                    </div>
                </div>

                <div class="row align-items-center">
                    <div class="col-lg-12">
                        
                         <div v-if="mensajeError != ''" class="alert alert-danger mb-4" role="alert">
                            <i class="fa fa-exclamation-triangle"></i> <strong>Un momento.</strong> <span>{{ mensajeError }}</span>
                        </div>

                        <div v-if="mensajeExito != ''" class="alert alert-success mb-4" role="alert">
                            <i class="fa fa-check"></i> <strong>Excelente.</strong> <span>{{ mensajeExito }}</span>
                        </div>

                        <div v-if="false">
                            <button type="button" class="default-btn-one submit-btn mb-3" @click="abrirModalTarjetas()">
                                <i class="fa fa-th mr-2"></i>Agregar Carta<span></span>
                            </button>
                        </div>
                        
                        <div class="text-center">
                            <div class="row">
                                <div class="col-md-3 mb-3" v-for="card in cards" :key="card.id">
                                    <div class="card">
										<div class="card-header text-center"><small>{{ card.name }} / {{ card.type }}</small></div>
										<div class="card-body"><img crossorigin="anonymous" :src="card.url_file" /></div>
										<div v-if="!paciente" class="card-footer text-center">
											<a href="javascript:;" class="text-danger" @click="abrirModalEliminar(card.id)"><i class="fa fa-trash"></i> Eliminar</a>
										</div>
									</div>
                                </div>

                                <div class="col-md-12" v-if="cards.length == 0">
                                    <div class="text-center">No hay cartas en este grupo...</div>
                                </div>

                                <div class="col-md-12" :class="{ 'd-none': datosCargados == true }">
                                    <div class="text-center">Cargando listado...</div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </div>
        </section>


        <div class="modal bg-black" tabindex="-1" v-if="modalTarjetasOpened == true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Agregar Carta</h5>
                        <a href="javascript:;" type="button" class="btn-close" @click="cerrarModalTarjetas()"><i class="fa fa-times"></i></a>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Carta</label>

                            <div v-if="card_url" id="laPhoto" class="mx-auto">
                                <img crossorigin="anonymous" :src="card_url" alt="Una imagen de la carta" class="inline rounded-xl" style="max-width:150px;max-height:150px;background-color:white;"/>
                                <button class="inline" @click="eliminarImage()">
                                    Eliminar
                                </button>
                            </div>
                            
                            <button class="rounded-full m-1 p-1 text-xl ok-button" onclick="document.getElementById('elFile').click();">
                                Subir foto
                            </button>

                            <input
                                id="elFile"
                                type="file"
                                accept=".jpeg,.jpg,.png,image/jpeg,image/png,.heic"
                                aria-label="upload image button"
                                style="display:none;"
                                @change="selectFile"
                                />
                        </div>

                        <div class="form-group">
                            <label>Nombre de la Carta</label>
                            <input v-model="card_name" name="card_name" type="text" class="form-control" data-requerido="1" />
                        </div>
                        <div class="form-group">
                            <label>Número de la Carta</label>
                            <input v-model="card_num" name="card_num" type="number" class="form-control" data-requerido="1" />
                        </div>
                        <div class="form-group">
                            <label>Frecuencia de la carta</label> <br>
                            <div style="float: left;">
                                <input v-model="card_freq" type="radio" id="hight" name="freq" value="alta">
                                <label for="hight"> Alta </label><br>
                            </div>
                            <div style="float: right;">
                                <input v-model="card_freq" type="radio"  id="low" name="freq" value="baja">
                                <label for="low">Baja</label> <br>
                            </div><br>
                            <label>Campo semántico</label>
                            <input v-model="card_sem" name="card_sem" style="float: right;" type="text" data-requerido="1" /> <br>
                            <label>Categoría gramatical</label>
                            <input v-model="card_gram" name="card_gram" style="float: right;" type="text" data-requerido="1" />
                        </div>

                        <div class="form-group" v-if="false">
                            <label>URL</label>
                            <input v-model="card_url" name="card_url" type="text" class="form-control" data-requerido="1" />
                        </div>

                        <div class="form-group" v-if="false">
                            <progress max="100" :value="card_upload_percentage" style="width:100%;"></progress>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="default-btn-one submit-btn" data-bs-dismiss="modal" @click="cerrarModalTarjetas()">Cerrar<span></span></button>
                        <button type="button" class="default-btn submit-btn" @click="agregarTarjeta()">Agregar<span></span></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal bg-black" v-if="modalEliminarOpened == true" id="delete" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Eliminar carta</h5>
                        <a href="javascript:;" type="button" class="btn-close" ><i class="fa fa-times"></i></a>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label>¿Deseas eliminar esta carta?</label>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="default-btn-one submit-btn" @click="cerrarModalTarjetas()" data-bs-dismiss="modal">Cancelar<span></span></button>
                        <button type="button" @click="eliminarCard()" class="default-btn submit-btn">Eliminar<span></span></button>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
    import TitleSection from '@/components/TitleSection'
    import Utilities from '@/plugins/Utilities.js'
    import axios from 'axios'

    const MyAccountCards = {
        name: 'MyAccountCards',
        components: {
            TitleSection,
        },
        data: function(){
            return {               
                datosCargados: true,
                mensajeExito: '',
                mensajeError: '',
                modalTarjetasOpened: false,
                modalEliminarOpened:false,

                cards: [],

                id: '',
                name: '',
                paciente: false,
                
                card_name: '',
                card_num: 0,
                card_gram:'',
                card_freq:'',
                card_sem:'',
                card_type: '',
                card_url: '',
                card_file: '',
                card_par: '',
                card_upload_percentage: 0,
            }
        },
        methods: {
            async selectFile(e) {
                const file = e.target.files[0];
                if(!this.card_name){
                    this.card_name = file.name.split('.')[0];
                }

                /* Make sure file exists */
                if (!file) return;

                /* create a reader */
                const readData = (f) =>  new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.readAsDataURL(f);
                });

                /* Read data */
                const data = await readData(file);

                const formData = new FormData()
                formData.append('file', data);
                formData.append('folder', 'lparking');
                formData.append('upload_preset', 'ad9ijidi');

                const response = await axios.post('https://api.cloudinary.com/v1_1/soporte2-blendarsys/upload', formData)

                this.card_url = response.data.secure_url;
            },
            eliminarImage(){
                this.card_url = undefined;
            },
            consultarCards(){
                this.datosCargados = false
                this.mensajeExito = ''
                this.mensajeError = ''
                    
                let _this = this
                this.mensajeError = ''

                const formData = new FormData()

                axios.post(Utilities.getApiURL()  + 'cards', formData)
                .then(response => {
                    _this.datosCargados = true

                    if(response.data.code == '1'){
                        _this.cards = response.data.cards
                    }
                    else{
                        _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                    }
                })
                .catch(error => {
                    _this.datosCargados = true
                    _this.mensajeError = error
                })
                
            },
            eliminarCard(){            
                    this.datosCargados = false
                    this.mensajeExito = ''
                    this.mensajeError = ''
                        
                    let _this = this
                    this.mensajeError = ''

                    const formData = new FormData()
                    formData.append('id', this.id)
                    axios.post(Utilities.getApiURL()  + 'cards/delete', formData)
                    .then(response => {
                        _this.datosCargados = true

                        if(response.data.code == '1'){
                            _this.consultarCards()

                            setTimeout(function(){
                                _this.mensajeExito = response.data.message
                            }, 1000)
                        }
                        else{
                            _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                        }
                    })
                    .catch(error => {
                        _this.datosCargados = true
                        _this.mensajeError = error
                    })
                    this.modalEliminarOpened = false
            },
            abrirModalTarjetas(){
                this.modalTarjetasOpened = true
                this.card_url = undefined;
                this.card_name = '';
                this.card_num = this.card_num ? (this.card_num + 1) : 1;
            },
            cerrarModalTarjetas(){
                this.modalTarjetasOpened = false
            },
            abrirModalEliminar(id){
                this.modalEliminarOpened = true
                this.id = id
            },
            cerrarModalEliminar(){
                this.modalEliminarOpened = false
            },
            subirArchivo(){
                this.card_file = this.$refs.card_file.files[0];
            },
            agregarTarjeta(){
             
                this.datosCargados = false
                this.mensajeExito = ''
                this.mensajeError = ''
                this.card_freq = this.card_freq.slice(0,1)
                this.card_gram = this.card_gram.slice(0,3)
                this.card_sem = this.card_sem.slice(0,3)
                this.card_type = this.card_freq + '_' + this.card_gram + '_' + this.card_sem
                let _this = this
                this.mensajeError = ''

                const formData = new FormData()
                formData.append('name', this.card_name)
                formData.append('num', String(this.card_num))

                formData.append('type', this.card_type)
                
                formData.append('url', this.card_url)
                formData.append('file', this.card_file)
                formData.append('par', this.card_par)

                const config = {
                    Headers: {'Content-Type': 'multipart/form-data'},
                    onUploadProgress: progressEvent => {
                        return parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
                    }
                }

                axios.post(Utilities.getApiURL()  + 'cards/add', formData, config)
                .then(response => {
                    _this.datosCargados = true

                    _this.cerrarModalTarjetas()
                    _this.card_name = ''
                    _this.card_file = ''

                    if(response.data.code == '1'){
                        
                        _this.consultarCards()

                        setTimeout(function(){
                            _this.mensajeExito = response.data.message
                        }, 1000)
                    }
                    else{
                        _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                    }
                })
                .catch(error => {
                    _this.datosCargados = true
                    _this.mensajeError = error
                })
                
            },
        },
        beforeMount: function(){
			if(!Utilities.isLogged()){
                this.$router.push({name:'login'})
			}

            this.consultarCards()
            this.paciente = sessionStorage.getItem("paciente") === 'true' ? true : false
		},
    }

    export default MyAccountCards;
</script>
<style scoped>
    .table tr:hover{
        cursor: pointer
    }

    .bg-black{
        background: rgba(0, 0, 0, 0.37)
    }

    .modal{
        display: initial;
    }

    .card-header{
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    
    .card-body{
        width: auto;
        height: 180px;
        background: #75072b;
    }
    
    .card-body img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
</style>