<template>
    <div>
        <title-section></title-section>
        
        <section class="services-section section-padding">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="section-title">
                            <router-link to="/my-account/rooms">
                                <i class="fa fa-arrow-left mr-2"></i>Regresar
                            </router-link>
                            <h2>Historial de {{ name }}</h2>
                        </div>
                    </div>
                </div>

                <div class="row align-items-center">
                    <div class="col-lg-12">
                        
                         <div v-if="mensajeError != ''" class="alert alert-danger mb-4" role="alert">
                            <i class="fa fa-exclamation-triangle"></i> <strong>Un momento.</strong> <span>{{ mensajeError }}</span>
                        </div>

                        <div v-if="mensajeExito != ''" class="alert alert-success mb-4" role="alert">
                            <i class="fa fa-check"></i> <strong>Excelente.</strong> <span>{{ mensajeExito }}</span>
                        </div>
                        
                        <history-table 
                            :history="history"
                        />

                    </div>
                </div>

            </div>
        </section>

    </div>
</template>

<script>
    import TitleSection from '@/components/TitleSection'
    import Utilities from '@/plugins/Utilities.js'
    import axios from 'axios'
    import HistoryTable from '../components/HistoryTable.vue'

    const MyAccountUsersView = {
        name: 'MyAccountHistoryView',
        components: {
            TitleSection,
            HistoryTable
        },
        data: function(){
            return {                
                datosCargados: true,
                mensajeExito: '',
                mensajeError: '',

                history: [],

                id: '',
                name: '',
            }
        },
        methods: {
            addZero(leInt){
                if(leInt < 10){
                    return '0' + leInt
                } else {
                    return leInt
                }
            },
            consultarData(){
                this.datosCargados = false
                this.mensajeExito = ''
                this.mensajeError = ''
                    
                let _this = this
                this.mensajeError = ''

                const formData = new FormData()
                formData.append('id', this.$route.params.id)

                axios.post(Utilities.getApiURL()  + 'rooms/get', formData)
                .then(response => {
                    _this.datosCargados = true

                    if(response.data.code == '1'){
                        _this.id = response.data.id
                        _this.name = response.data.name

                    }
                    else{
                        _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                    }
                })
                .catch(error => {
                    _this.datosCargados = true
                    _this.mensajeError = error
                })

                axios.post(Utilities.getApiURL()  + 'history/room/get', formData)
                .then(response => {
                    _this.datosCargados = true

                    if(response.data.code == '1'){
                        _this.history = response.data.history
                        _this.history.forEach(
                            (line) => {
                                line.cuando = new Date(line.cuando)
                            }
                        )
                    }
                    else{
                        _this.history = []
                        _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                    }
                })
                .catch(error => {
                    _this.datosCargados = true
                    _this.mensajeError = error
                })
                
            },
        },
        beforeMount: function(){
			if(!Utilities.isLogged()){
                this.$router.push({name:'login'})
			}

            this.consultarData()
		},
    }

    export default MyAccountUsersView;
</script>

<style scoped>
    .table tr:hover{
        cursor: pointer
    }

    .bg-black{
        background: rgba(0, 0, 0, 0.37)
    }

    .modal{
        display: initial;
    }

    .url-link{
        padding: 10px;
        border-radius: 10px;
    }

    .url-link:hover{
        background: #c4c4c4;
        cursor: pointer;
    }
</style>