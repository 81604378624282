<template>             
    <div class="text-center">
        <table class="table table-striped table-hover">
            <tbody>
                <tr v-for="(line, index) in history" :key="index" :class="{ 'd-none': datosListadoCargados == false }">
                    <td>El <b>{{ addZero(line.cuando.getDate()) }}/{{ addZero(line.cuando.getMonth() + 1) }}/{{ line.cuando.getFullYear() }}</b>
                        a las <b>{{ addZero(line.cuando.getHours()) }}:{{ addZero(line.cuando.getMinutes()) }}:{{ addZero(line.cuando.getSeconds()) }}</b></td>
                    <td>{{ line.mensaje }}</td>
                </tr>

                <tr v-if="!history || history.length == 0">
                    <td colspan="4">No hay historial en este grupo...</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>

    const HistoryTable = {
        name: 'HistoryTable',
        props: ['history'],
        methods: {
            addZero(leInt){
                if(leInt < 10){
                    return '0' + leInt
                } else {
                    return leInt
                }
            },
        },
    }

    export default HistoryTable;
</script>



<style scoped>
    .table tr:hover{
        cursor: pointer
    }

    .bg-black{
        background: rgba(0, 0, 0, 0.37)
    }

    .modal{
        display: initial;
    }

    .url-link{
        padding: 10px;
        border-radius: 10px;
    }

    .url-link:hover{
        background: #c4c4c4;
        cursor: pointer;
    }
</style>