<template>
    <div>
        <title-section></title-section>
        
        <section class="services-section section-padding">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="section-title">
                            <router-link to="/my-account/rooms">
                                <i class="fa fa-arrow-left mr-2"></i>Regresar
                            </router-link>
                            <h2>Integrantes de {{ name }}</h2>
                        </div>
                    </div>
                </div>

                <div class="row align-items-center">
                    <div class="col-lg-12">
                        
                         <div v-if="mensajeError != ''" class="alert alert-danger mb-4" role="alert">
                            <i class="fa fa-exclamation-triangle"></i> <strong>Un momento.</strong> <span>{{ mensajeError }}</span>
                        </div>

                        <div v-if="mensajeExito != ''" class="alert alert-success mb-4" role="alert">
                            <i class="fa fa-check"></i> <strong>Excelente.</strong> <span>{{ mensajeExito }}</span>
                        </div>

                        <div>
                            <button type="button" class="default-btn-one submit-btn mb-3" @click="abrirModalPacientes()">
                                <i class="fa fa-user mr-2"></i>Agregar Paciente<span></span>
                            </button>

                            <button type="button" class="default-btn-one submit-btn mb-3 ml-3" @click="abrirModalTerapeutas()">
                                <i class="fa fa-glasses mr-2"></i>Agregar Terapeuta<span></span>
                            </button>
                        </div>
                        
                        <div class="text-center">
                            <table class="table table-striped table-hover">
                                <tbody>
                                    <tr v-for="room_client in rooms_clients" :key="room_client.id" :class="{ 'd-none': datosListadoCargados == false }">
                                        <td>{{ room_client.id_system }}</td>
                                        <td>{{ room_client.name }}</td>
                                        <td>{{ room_client.role }}</td>
                                        <td class="text-left">
                                            <div @click="copyLinkToClipboard(room_client.url)"><i class="fa fa-copy mr-1"></i> <span class="url-link">{{ room_client.url }}</span></div>
                                        </td>
                                        <!-- <button @click="ingresar(room_client.client.url)">Ingresar</button> -->
                                        <td class="text-right">
                                            <a href="javascript:;" @click="eliminarRoomClient(room_client.id)"><i class="fa fa-trash"></i></a>
                                        </td>
                                    </tr>

                                    <tr v-if="rooms_clients.length == 0">
                                        <td colspan="4">No hay integrantes en este grupo...</td>
                                    </tr>

                                    <tr :class="{ 'd-none': datosCargados == true }">
                                        <td colspan="4">Cargando listado...</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>

            </div>
        </section>


        <div class="modal bg-black" tabindex="-1" v-if="modalPacientesOpened == true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Listado de Pacientes</h5>
                        <a href="javascript:;" type="button" class="btn-close" @click="cerrarModalPacientes()"><i class="fa fa-times"></i></a>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Pacientes</label>
                            <select v-model="paciente_seleccionado" name="paciente_seleccionado" class="form-control" data-requerido="1">
                                <option value="">Seleccione una opcion</option>
                                <option v-for="paciente in listado_pacientes" :key="paciente.id" :value="paciente.id">({{ paciente.id_system }}) {{ paciente.name }}</option>
                            </select>                                
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="default-btn-one submit-btn" data-bs-dismiss="modal" @click="cerrarModalPacientes()">Cerrar<span></span></button>
                        <button type="button" class="default-btn submit-btn" @click="agregarPaciente()">Agregar<span></span></button>
                    </div>
                </div>
            </div>
        </div>


        <div class="modal bg-black" tabindex="-1" v-if="modalTerapeutasOpened == true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Listado de Terapeutas</h5>
                        <a href="javascript:;" type="button" class="btn-close" @click="cerrarModalTerapeutas()"><i class="fa fa-times"></i></a>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Terapeutas</label>
                            <select v-model="terapeuta_seleccionado" name="terapeuta_seleccionado" class="form-control" data-requerido="1">
                                <option value="">Seleccione una opcion</option>
                                <option v-for="terapeuta in listado_terapeutas" :key="terapeuta.id" :value="terapeuta.id">({{ terapeuta.id_system }}) {{ terapeuta.name }}</option>
                            </select>                                
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="default-btn-one submit-btn" data-bs-dismiss="modal" @click="cerrarModalTerapeutas()">Cerrar<span></span></button>
                        <button type="button" class="default-btn submit-btn" @click="agregarTerapeuta()">Agregar<span></span></button>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
    import TitleSection from '@/components/TitleSection'
    import Utilities from '@/plugins/Utilities.js'
    import axios from 'axios'



    const MyAccountUsersView = {
        name: 'MyAccountUsersView',
        components: {
            TitleSection,
        },
        data: function(){
            return {                
                datosCargados: true,
                mensajeExito: '',
                mensajeError: '',
                modalPacientesOpened: false,
                modalTerapeutasOpened: false,

                rooms_clients: [],
                listado_pacientes: [],
                listado_terapeutas: [],

                id: '',
                name: '',

                paciente_seleccionado: '',
                terapeuta_seleccionado: '',
            }
        },
        methods: {
            consultarData(){
                this.datosCargados = false
                this.mensajeExito = ''
                this.mensajeError = ''
                    
                let _this = this
                this.mensajeError = ''

                const formData = new FormData()
                formData.append('id', this.$route.params.id)

                axios.post(Utilities.getApiURL()  + 'rooms/get', formData)
                .then(response => {
                    _this.datosCargados = true

                    if(response.data.code == '1'){
                        _this.id = response.data.id
                        _this.name = response.data.name
        

                    }
                    else{
                        _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                    }
                })
                .catch(error => {
                    _this.datosCargados = true
                    _this.mensajeError = error
                })
                
            },
            ingresar(){
                this.datosCargados = false
                this.mensajeExito = ''
                this.mensajeError = ''
                    
                let _this = this
                this.mensajeError = ''

                const formData = new FormData()
                //formData.append('token', val)

                axios.post(Utilities.getApiURL()  + 'login', formData)
                .then(response => {
                    _this.datosCargados = true

                    if(response.data.code == '1'){
                        _this.rooms_clients = response.data.rooms_clients
                        _this.listado_pacientes = response.data.listado_pacientes
                        _this.listado_terapeutas = response.data.listado_terapeutas
                    }
                    else{
                        _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                    }
                })
                .catch(error => {
                    _this.datosCargados = true
                    _this.mensajeError = error
                })
            },
            consultarRoomsUsers(){
                this.datosCargados = false
                this.mensajeExito = ''
                this.mensajeError = ''
                    
                let _this = this
                this.mensajeError = ''

                const formData = new FormData()
                formData.append('id', this.$route.params.id)

                function modificarURL(lista) {
                    return lista.map(item => {
                        if (item.url) {
                            item.url = item.url.replace("http://localhost:8000/#/", Utilities.getURL());
                        }
                        return item;
                    });
                }

                axios.post(Utilities.getApiURL()  + 'rooms-clients', formData)
                .then(response => {
                    _this.datosCargados = true
      
                    if(response.data.code == '1'){
                        _this.rooms_clients = modificarURL(response.data.rooms_clients)
                        _this.listado_pacientes = response.data.listado_pacientes
                        _this.listado_terapeutas = response.data.listado_terapeutas
                    }
                    else{
                        _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                    }
                })
                .catch(error => {
                    _this.datosCargados = true
                    _this.mensajeError = error
                })
                
            },
            eliminarRoomClient(id){
                this.datosCargados = false
                this.mensajeExito = ''
                this.mensajeError = ''
                    
                let _this = this
                this.mensajeError = ''

                const formData = new FormData()
                formData.append('id', id)

                axios.post(Utilities.getApiURL()  + 'rooms-clients/delete', formData)
                .then(response => {
                    _this.datosCargados = true

                    if(response.data.code == '1'){
                        _this.consultarRoomsUsers()

                        setTimeout(function(){
                            _this.mensajeExito = response.data.message
                        }, 1000)
                    }
                    else{
                        _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                    }
                })
                .catch(error => {
                    _this.datosCargados = true
                    _this.mensajeError = error
                })
                
            },
            abrirModalPacientes(){
                this.modalPacientesOpened = true
            },
            cerrarModalPacientes(){
                this.modalPacientesOpened = false
            },
            agregarPaciente(){
                this.datosCargados = false
                this.mensajeExito = ''
                this.mensajeError = ''
                    
                let _this = this
                this.mensajeError = ''

                const formData = new FormData()
                formData.append('room_id', this.id)
                formData.append('id', this.paciente_seleccionado)                

                axios.post(Utilities.getApiURL()  + 'rooms-clients/add', formData)
                .then(response => {
                    _this.datosCargados = true

                    _this.cerrarModalPacientes()
                    _this.paciente_seleccionado = ''

                    if(response.data.code == '1'){
                        
                        _this.consultarRoomsUsers()

                        setTimeout(function(){
                            _this.mensajeExito = response.data.message
                        }, 1000)
                    }
                    else{
                        _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                    }
                })
                .catch(error => {
                    _this.datosCargados = true
                    _this.mensajeError = error
                })
                
            },
            abrirModalTerapeutas(){
                this.modalTerapeutasOpened = true
            },
            cerrarModalTerapeutas(){
                this.modalTerapeutasOpened = false
            },
            agregarTerapeuta(){
                this.datosCargados = false
                this.mensajeExito = ''
                this.mensajeError = ''
                    
                let _this = this
                this.mensajeError = ''

                const formData = new FormData()
                formData.append('room_id', this.id)
                formData.append('id', this.terapeuta_seleccionado)                

                axios.post(Utilities.getApiURL()  + 'rooms-clients/add', formData)
                .then(response => {
                    _this.datosCargados = true

                    _this.cerrarModalTerapeutas()
                    _this.terapeuta_seleccionado = ''

                    if(response.data.code == '1'){
                        
                        _this.consultarRoomsUsers()

                        setTimeout(function(){
                            _this.mensajeExito = response.data.message
                        }, 1000)
                    }
                    else{
                        _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                    }
                })
                .catch(error => {
                    _this.datosCargados = true
                    _this.mensajeError = error
                })
                
            },
            copyLinkToClipboard(url){
                navigator.clipboard.writeText(url);
                
                let _this = this
                _this.mensajeExito = "La url ha sido copiada exitosamente"

                setTimeout(function(){
                    _this.mensajeExito = ""
                }, 3000)
            }
        },
        beforeMount: function(){
			if(!Utilities.isLogged()){
                this.$router.push({name:'login'})
			}

            this.consultarData()
            this.consultarRoomsUsers()
		},
    }

    export default MyAccountUsersView;
</script>

<style scoped>
    .table tr:hover{
        cursor: pointer
    }

    .bg-black{
        background: rgba(0, 0, 0, 0.37)
    }

    .modal{
        display: initial;
    }

    .url-link{
        padding: 10px;
        border-radius: 10px;
    }

    .url-link:hover{
        background: #c4c4c4;
        cursor: pointer;
    }
</style>