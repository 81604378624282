<template>
    <div>
        <title-section></title-section>
        
        <section class="services-section section-padding">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="section-title">
                            <router-link to="/my-account/rooms">
                                <i class="fa fa-arrow-left mr-2"></i>Regresar
                            </router-link>
                            <h2>Sets de {{ name }}</h2>
                        </div>
                    </div>
                </div>

                <div class="row align-items-center">
                    <div class="col-lg-12">
                        
                         <div v-if="mensajeError != ''" class="alert alert-danger mb-4" role="alert">
                            <i class="fa fa-exclamation-triangle"></i> <strong>Un momento.</strong> <span>{{ mensajeError }}</span>
                        </div>

                        <div v-if="mensajeExito != ''" class="alert alert-success mb-4" role="alert">
                            <i class="fa fa-check"></i> <strong>Excelente.</strong> <span>{{ mensajeExito }}</span>
                        </div>

                        <div class="row">
                            <div class="col-lg-6">
                                <button type="button" class="default-btn-one submit-btn mb-3" @click="abrirModalTarjetas()">
                                    <i class="fa fa-th mr-2"></i>Agregar Set<span></span>
                                </button>
                            </div>

                            <div class="col-lg-6 text-right">
                                <button type="button" class="default-btn-one submit-btn mb-3" @click="eliminarTodasTarjetas()">
                                    <i class="fa fa-trash mr-2"></i>Eliminar todas las Sets<span></span>
                                </button>
                            </div>
                        </div>
                        
                        <div class="text-center">
                            <div class="row">
                                <div class="col-md-3 mb-3" v-for="room_set in rooms_sets" :key="room_set.id">
                                    <div class="card">
										<div class="card-header text-center"><small>{{ room_set.name }}</small></div>
										<div class="card-footer text-center">
											<a href="javascript:;" class="text-danger" @click="eliminarRoomCard(room_set.id)"><i class="fa fa-trash"></i> Eliminar</a>
										</div>
									</div>
                                </div>

                                <div class="col-md-12" v-if="rooms_sets.length == 0">
                                    <div class="text-center">No hay sets en este grupo...</div>
                                </div>

                                <div class="col-md-12" :class="{ 'd-none': datosCargados == true }">
                                    <div class="text-center">Cargando listado...</div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </div>
        </section>


        <div class="modal bg-black" tabindex="-1" v-if="modalTarjetasOpened == true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Agregar Set</h5>
                        <a href="javascript:;" type="button" class="btn-close" @click="cerrarModalTarjetas()"><i class="fa fa-times"></i></a>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Set de Cartas</label>
                            <select v-model="set_id" name="set_id" class="form-control" data-requerido="1">
                                <option value="">Seleccione un set</option>
                                <option v-for="set in listado_sets" :key="set.id" :value="set.id">{{ set.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="default-btn-one submit-btn" data-bs-dismiss="modal" @click="cerrarModalTarjetas()">Cerrar<span></span></button>
                        <button type="button" class="default-btn submit-btn" @click="agregarSet()">Agregar<span></span></button>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
    import TitleSection from '@/components/TitleSection'
    import Utilities from '@/plugins/Utilities.js'
    import axios from 'axios'

    const MyAccountCardsView = {
        name: 'MyAccountCardsView',
        components: {
            TitleSection,
        },
        data: function(){
            return {                
                datosCargados: true,
                mensajeExito: '',
                mensajeError: '',
                modalTarjetasOpened: false,

                rooms_sets: [],
                listado_sets: [],

                id: '',
                name: '',

                set_name: '',
                set_id: '',
            }
        },
        computed: {
            isDisabled(){
                return this.rooms_sets.length == 0 ? false : true
            }
        },
        methods: {
            consultarData(){
                this.datosCargados = false
                this.mensajeExito = ''
                this.mensajeError = ''
                    
                let _this = this
                this.mensajeError = ''

                const formData = new FormData()
                formData.append('id', this.$route.params.id)

                axios.post(Utilities.getApiURL()  + 'rooms/get', formData)
                .then(response => {
                    _this.datosCargados = true

                    if(response.data.code == '1'){
                        _this.id = response.data.id
                        _this.name = response.data.name

                    }
                    else{
                        _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                    }
                })
                .catch(error => {
                    _this.datosCargados = true
                    _this.mensajeError = error
                })
                
            },
            consultarRoomsSets(){
                this.datosCargados = false
                this.mensajeExito = ''
                this.mensajeError = ''
                    
                let _this = this
                this.mensajeError = ''

                const formData = new FormData()
                formData.append('id', this.$route.params.id)

                axios.post(Utilities.getApiURL()  + 'rooms-cards', formData)
                .then(response => {
                    _this.datosCargados = true

                    if(response.data.code == '1'){
                        _this.rooms_sets = response.data.rooms_sets
                        _this.listado_sets = response.data.listado_sets
                    }
                    else{
                        _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                    }
                })
                .catch(error => {
                    _this.datosCargados = true
                    _this.mensajeError = error
                })
                
            },
            eliminarRoomCard(id){
                this.datosCargados = false
                this.mensajeExito = ''
                this.mensajeError = ''
                    
                let _this = this
                this.mensajeError = ''

                const formData = new FormData()
                formData.append('id', id)

                axios.post(Utilities.getApiURL()  + 'rooms-cards/delete', formData)
                .then(response => {
                    _this.datosCargados = true

                    if(response.data.code == '1'){
                        _this.consultarRoomsSets()

                        setTimeout(function(){
                            _this.mensajeExito = response.data.message
                        }, 1000)
                    }
                    else{
                        _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                    }
                })
                .catch(error => {
                    _this.datosCargados = true
                    _this.mensajeError = error
                })
                
            },
            eliminarTodasTarjetas(){
                if(this.rooms_sets.length == 0){
                    this.mensajeError = 'No dispone de Sets suficientes'
                }else{
                    if(confirm("¿Realmente desea eliminar todas las sets de este grupo?")){
                    this.datosCargados = false
                    this.mensajeExito = ''
                    this.mensajeError = ''
                        
                    let _this = this
                    this.mensajeError = ''

                    const formData = new FormData()
                    formData.append('room_id', this.id)

                    axios.post(Utilities.getApiURL()  + 'rooms-cards/delete-all', formData)
                    .then(response => {
                        _this.datosCargados = true

                        if(response.data.code == '1'){
                            _this.consultarRoomsSets()

                            setTimeout(function(){
                                _this.mensajeExito = response.data.message
                            }, 1000)
                        }
                        else{
                            _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                        }
                    })
                    .catch(error => {
                        _this.datosCargados = true
                        _this.mensajeError = error
                    })
                }
                }
            },
            abrirModalTarjetas(){
                this.modalTarjetasOpened = true
            },
            cerrarModalTarjetas(){
                this.modalTarjetasOpened = false
            },
            subirArchivo(){
                this.card_file = this.$refs.card_file.files[0];
            },
            agregarSet(){
                this.datosCargados = false
                this.mensajeExito = ''
                this.mensajeError = ''
                    
                let _this = this
                this.mensajeError = ''
                const formData = new FormData()
                formData.append('room_id', this.$route.params.id)
                formData.append('set_id', this.set_id)

                if(this.set_id != ""){
                    axios.post(Utilities.getApiURL()  + 'rooms-cards/add', formData)
                    .then(response => {
                        _this.datosCargados = true

                        _this.cerrarModalTarjetas()
                        _this.set_id = ''

                        if(response.data.code == '1'){
                            
                            _this.consultarRoomsSets()

                            setTimeout(function(){
                                _this.mensajeExito = response.data.message
                            }, 1000)
                        }
                        else{
                            _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                        }
                    })
                    .catch(error => {
                        _this.datosCargados = true
                        _this.mensajeError = error
                        _this.modalTarjetasOpened = false
                    })
                }else{
                    this.datosCargados = true
                    this.mensajeError = "Debe seleccionar un Set válido.."
                    this.modalTarjetasOpened = false
                }
                
                
            },
        },
        beforeMount: function(){
			if(!Utilities.isLogged()){
                this.$router.push({name:'login'})
			}

            this.consultarData()
            this.consultarRoomsSets()
		},
    }

    export default MyAccountCardsView;
</script>

<style scoped>
    .table tr:hover{
        cursor: pointer
    }

    .bg-black{
        background: rgba(0, 0, 0, 0.37)
    }

    .modal{
        display: initial;
    }

    .card-header{
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    
    .card-body{
        width: auto;
        height: 180px;
        background: #75072b;
    }
    
    .card-body img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
</style>