<template>
    <div>
        <title-section></title-section>
        
        <section class="services-section section-padding">
            <div class="">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="section-title">
                            <router-link to="/my-account">
                                <i class="fa fa-arrow-left mr-2"></i>Regresar
                            </router-link>
                            <h2>Pacientes</h2>
                        </div>
                    </div>
                </div>

                <div class="row align-items-center">
                    <div class="col-lg-12">
                        
                         <div v-if="mensajeError != ''" class="alert alert-danger mb-4" role="alert">
                            <i class="fa fa-exclamation-triangle"></i> <strong>Un momento.</strong> <span>{{ mensajeError }}</span>
                        </div>

                        <div v-if="mensajeExito != ''" class="alert alert-success mb-4" role="alert">
                            <i class="fa fa-check"></i> <strong>Excelente.</strong> <span>{{ mensajeExito }}</span>
                        </div>

                        <div class="text-right mb-3">
                            <a href="javascript:;" @click="cambiarModoAgregarForm()">
                                <i class="fa fa-plus mr-2"></i>Nuevo Paciente
                            </a>
                        </div>

                        <div class="row">
                            
                            <div class="col-lg-5">

                                <div class="row">
                                    <div class="col-lg-12 col-md-12">
                                        <form class="contact-form form"> 
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <input v-model="search" name="search" type="text" class="form-control" placeholder="Buscar..." data-requerido="1">
                                                    <a href="javascript:;" class="input-group-text" @click='consultarPacientes'><i class="fa fa-search"></i></a>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    
                                    <div class="col-lg-12 col-md-12">   
                                        <table class="table table-striped table-hover">
                                            <tbody>
                                                <tr v-for="pacient in pacients" :key="pacient.id" @click="consultarData(pacient.id)" :class="{ 'd-none': datosListadoCargados == false }">
                                                    <td>{{ pacient.id_system }}</td>
                                                    <td>{{ pacient.name }}</td>
                                                    <td class="text-right">
                                                        <i class="fa fa-pencil"></i>
                                                    </td>
                                                </tr>

                                                <tr v-if="pacients.length == 0">
                                                    <td colspan="3">No hay resultados que coincidan con su busqueda...</td>
                                                </tr>

                                                <tr :class="{ 'd-none': datosListadoCargados == true }">
                                                    <td colspan="3">Cargando listado...</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                
                            </div>

                            <div class="col-lg-7">
                                <div class="card">
                                    <div class="card-body">
                                        <form class="contact-form form" @submit="realizarSubmit"> 
                                            <div class="row">
                                                
                                                
                                                <div class="col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <label>Identificador</label>
                                                        <input v-model="id_system" name="id_system" type="text" class="form-control" data-requerido="1">
                                                    </div>
                                                </div>

                                                <div class="col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <label>Nombre</label>
                                                        <input v-model="first_name" name="first_name" type="text" class="form-control" data-requerido="0">
                                                    </div>
                                                </div>

                                                <div class="col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <label>Apellidos</label>
                                                        <input v-model="last_name" name="last_name" type="text" class="form-control" data-requerido="0">
                                                    </div>
                                                </div>

                                                <div class="col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <label>Correo Electrónico</label>
                                                        <input v-model="email" name="email" type="text" class="form-control" data-requerido="0">
                                                    </div>
                                                </div>

                                                <div class="col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <label>Notas</label>
                                                        <textarea v-model="notes" name="notes" type="text" class="form-control" rows="3" data-requerido="0"></textarea>
                                                    </div>
                                                </div>

                                                <div class="col-lg-12 col-md-12 text-center mt-4 mb-4" :class="{ 'd-none': datosCargados == false }">
                                                    
                                                    <button type="submit" class="default-btn submit-btn" v-if="modo_form == 'agregar'">
                                                         <i class="fa fa-plus mr-2"></i>Agregar Paciente<span></span>
                                                    </button>

                                                    <button type="submit" class="default-btn submit-btn mb-3" v-if="modo_form == 'editar'">
                                                         <i class="fa fa-edit mr-2"></i>Editar Paciente<span></span>
                                                    </button>

                                                    <div class="col-lg-12 col-md-12 text-center" v-if="modo_form == 'editar'">
                                                        <a href="javascript:;" @click="eliminarPaciente()"><i class="fa fa-times mr-2"></i>Eliminar Paciente</a>
                                                    </div>
                                                </div>

                                                <div class="col-lg-12 col-md-12 text-center mb-4" :class="{ 'd-none': datosCargados == true }">
                                                    <p>Cargando datos...</p>
                                                </div>

                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </div>
        </section>

    </div>
</template>

<script>
    import TitleSection from '@/components/TitleSection'
    import Utilities from '@/plugins/Utilities.js'
    import axios from 'axios'

    const MyAccountPacients = {
        name: 'MyAccountPacients',
        components: {
            TitleSection,
        },
        data: function(){
            return {                
                datosCargados: true,
                datosListadoCargados: true,
                modo_form: 'agregar',
                mensajeExito: '',
                mensajeError: '',

                search: '',
                pacients: [],

                id: '',
                id_system: '',
                first_name: '',
                last_name: '',
                email: '',
                notes: '',
            }
        },
        methods: {
            cambiarModoAgregarForm(){
                this.id = ''
                this.id_system = ''
                this.first_name = ''
                this.last_name = ''
                this.email = ''
                this.notes = ''

                this.modo_form = 'agregar'
            },
            consultarPacientes(){
                this.datosListadoCargados = false
                this.mensajeExito = ''
                this.mensajeError = ''

                    
                let _this = this
                this.mensajeError = ''

                const formData = new FormData()
                formData.append('search', this.search)

                axios.post(Utilities.getApiURL()  + 'pacients', formData)
                .then(response => {
                    _this.datosListadoCargados = true

                    if(response.data.code == '1'){
                        _this.pacients = response.data.pacients
                    }
                    else{
                        _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                    }
                })
                .catch(error => {
                    _this.datosListadoCargados = true
                    _this.mensajeError = error
                })
                
            },
            consultarData(id){
                this.datosCargados = false
                this.mensajeExito = ''
                this.mensajeError = ''
                    
                let _this = this
                this.mensajeError = ''

                const formData = new FormData()
                formData.append('id', id)

                axios.post(Utilities.getApiURL()  + 'pacients/get', formData)
                .then(response => {
                    _this.datosCargados = true

                    if(response.data.code == '1'){
                        _this.id = response.data.id
                        _this.id_system = response.data.id_system
                        _this.first_name = response.data.first_name
                        _this.last_name = response.data.last_name
                        _this.email = response.data.email
                        _this.notes = response.data.notes

                        _this.modo_form = 'editar'
                    }
                    else{
                        _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                    }
                })
                .catch(error => {
                    _this.datosCargados = true
                    _this.mensajeError = error
                })
                
            },
            realizarSubmit(e){
                e.preventDefault();
                
                this.datosCargados = false
                this.mensajeExito = ''
                this.mensajeError = ''

                if( this.id_system != '' && 
                    this.first_name != '' &&
                    this.last_name != '' &&
                    this.email != '' ){
                    
                    let _this = this
                    this.mensajeError = ''

                    let url_modo = ''
                    if(this.modo_form == 'agregar'){
                        url_modo = 'add'
                    }
                    else{
                        url_modo = 'edit'
                    }

                    const formData = new FormData()
                    formData.append('id', this.id)
                    formData.append('id_system', this.id_system)
                    formData.append('first_name', this.first_name)
                    formData.append('last_name', this.last_name)
                    formData.append('email', this.email)
                    formData.append('notes', this.notes)

                    axios.post(Utilities.getApiURL()  + 'pacients/' + url_modo, formData)
                    .then(response => {
                        _this.datosCargados = true

                        if(response.data.code == '1'){
                            _this.search = ''
                            _this.consultarPacientes()

                            _this.id = ''
                            _this.id_system = ''
                            _this.first_name = ''
                            _this.last_name = ''
                            _this.email = ''
                            _this.notes = ''

                            _this.modo_form = 'agregar'

                            setTimeout(function(){
                                _this.mensajeExito = response.data.message
                            }, 1000)
                        }
                        else{
                            _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                        }
                    })
                    .catch(error => {
                        _this.datosCargados = true
                        _this.mensajeError = error
                    })
                }
                else if(this.id_system != ''){
                    let _this = this
                    this.mensajeError = ''

                    let url_modo = ''
                    if(this.modo_form == 'agregar'){
                        url_modo = 'add'
                    }
                    else{
                        url_modo = 'edit'
                    }

                    const formData = new FormData()
                    formData.append('id', this.id)
                    formData.append('id_system', this.id_system)
                    formData.append('first_name', this.first_name)
                    formData.append('last_name', this.last_name)
                    formData.append('email', this.email)
                    formData.append('notes', this.notes)

                    axios.post(Utilities.getApiURL()  + 'pacients/' + url_modo, formData)
                    .then(response => {
                        _this.datosCargados = true

                        if(response.data.code == '1'){
                            _this.search = ''
                            _this.consultarPacientes()

                            _this.id = ''
                            _this.id_system = ''
                            _this.first_name = ''
                            _this.last_name = ''
                            _this.email = ''
                            _this.notes = ''

                            _this.modo_form = 'agregar'

                            setTimeout(function(){
                                _this.mensajeExito = response.data.message
                            }, 1000)
                        }
                        else{
                            _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                        }
                    })
                    .catch(error => {
                        _this.datosCargados = true
                        _this.mensajeError = error
                    })
                }
                else{
                    this.datosCargados = true
                    this.mensajeError = "Debes poner al menos un identificador para agregar o editar el paciente..."
                }
                
            },
            eliminarPaciente(){
                this.datosCargados = false
                this.mensajeExito = ''
                this.mensajeError = ''
                    
                let _this = this
                this.mensajeError = ''

                const formData = new FormData()
                formData.append('id', this.id)

                axios.post(Utilities.getApiURL()  + 'pacients/delete', formData)
                .then(response => {
                    _this.datosCargados = true

                    if(response.data.code == '1'){
                         _this.search = ''
                        _this.consultarPacientes()

                        _this.id = ''
                        _this.id_system = ''
                        _this.first_name = ''
                        _this.last_name = ''
                        _this.email = ''
                        _this.notes = ''

                        _this.modo_form = 'agregar'

                        setTimeout(function(){
                            _this.mensajeExito = response.data.message
                        }, 1000)
                    }
                    else{
                        _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                    }
                })
                .catch(error => {
                    _this.datosCargados = true
                    _this.mensajeError = error
                })
                
            },
        },
        watch: {
            search: function(new_value){
                if(new_value.length >= 3 || new_value.length == 0){
                    this.consultarPacientes()
                }
            }
        },
        beforeMount: function(){
			if(!Utilities.isLogged()){
                this.$router.push({name:'login'})
			}

            this.consultarPacientes()
		}
    }

    export default MyAccountPacients;
</script>

<style scoped>
    .table tr:hover{
        cursor: pointer
    }
</style>