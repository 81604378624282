//https://developers.zoom.us/docs/api/rest/reference/zoom-api/methods/#operation/meetingCreate

// export const URI_API_ZOOM = `http://localhost:4001/api/signature`;
export const URI_API_ZOOM = `https://test2.akutalent.com/zoom/api/signature`;

//  id user zoom, se obtiene desde la api "server-to-server => midominio/api/users pasar token"
// pedir token https://zoom.us/oauth/token?grant_type=account_credentials&account_id=dTsEukgZQeyph75-Nvk8rw
// enpoint para perdir id user https://test2akutalent.com/api/users

// USER GUADALUPE ZOOM
export const idUserZoom = 'it1y_Q_KTlqftZ8TI2tcEQ';
// export const URI_API_ZOOM_MEETINGS = `http://localhost:4001/api/meetings/${idUserZoom}`;
export const URI_API_ZOOM_MEETINGS = `https://test2.akutalent.com/zoom/api/meetings/${idUserZoom}`;
export const SDK_KEY = 'nNYIhQ7kT1W2oS29KrJ0pw';
export const PASSWORD = '123';
