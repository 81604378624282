<template>
  <div>
    
    <!-- Start Navbar Area -->
    <div
       v-if="usuario_logueado"
      class="navbar-area"
      style="display: flex; justify-content: center;"
      >
      <div class="techvio-responsive-nav">
        <div class="container">
          <div class="techvio-responsive-menu">
            <div class="logo">
              
              <router-link to="/" class="titulo-logo">
                <img src="/assets/img/logo.png" style="max-width: 100px;" />
              </router-link>
              
            </div>
          </div>
        </div>
      </div>
      <div class="techvio-nav" style="max-width: 1920px; width: 100%;">
        <div class="container">
          <nav class="navbar navbar-expand-md navbar-light">
            
            <router-link to="/" class="navbar-brand titulo-logo">
              <img src="/assets/img/logo.png" style="max-width:200px;" />
            </router-link>
            
            
            <div class="collapse navbar-collapse mean-menu" v-if="noPublic" id="navbarSupportedContent">
              <ul class="navbar-nav" style="visibility:hidden;">
                <li class="nav-item"> 
                  <router-link to="/" class="nav-link">Inicio</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/" class="nav-link">Nosotros</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/" class="nav-link">Servicios</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/" class="nav-link">Contacto</router-link>
                </li>
              </ul>
              
              <div class="other-option">
                <router-link to="/my-account" class="default-btn mr-3">Mi Cuenta</router-link>
                <router-link to="/logout" class="default-btn"><i class="fa fa-power-off"></i></router-link>
              </div>

            </div>
          </nav>
        </div>
      </div>
    </div>
    <!-- End Navbar Area -->

    
    <slot></slot>
      
    
    <!-- Start Copy Right Section -->
    <div class="copyright-area"
       v-if="usuario_logueado">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-6">
            <p> <i class="far fa-copyright"></i> 2022 iGala - Todos los derechos reservados.</p>
          </div>
          <div class="col-lg-6 col-md-6">
            <ul>
              <li> <a href="javascript:;">Terminos y Condiciones</a>
              </li>
              <li> <a href="javascript:;">Politica de Privacidad</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- End Copy Right Section -->
    

  </div>
</template>

<script>    
    import Utilities from '@/plugins/Utilities.js'

    const Layout = {
        name: 'layout',
        data: function(){
            return {
              usuario_logueado: false,
            }
        },
        methods: {
          noPublic(){
              if( this.$route.name != 'public-session')
                this.noPublic = true
              else
                this.noPublic = false 
            },
            refreshLogued(){
              if(Utilities.isLogged()){
                this.usuario_logueado = true
              }
              else{
                this.usuario_logueado = false
              }
            }
        },
        beforeMount: function(){
          this.refreshLogued()
          this.noPublic()
        }
    }

    export default Layout;
</script>

<style scoped>
  .titulo-logo{
    color: white !important;
    font-size: 24px;
    font-weight: bold;
  }

  .titulo-logo:hover{
    color: white;
    font-size: 28px;
  }
</style>