<template>
    <div>
        <title-section></title-section>
        
        <section class="services-section section-padding">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="section-title">
                            <router-link to="/my-account/sets">
                                <i class="fa fa-arrow-left mr-2"></i>Regresar
                            </router-link>
                            <h2>Cartas de {{ name }}</h2>
                        </div>
                    </div>
                </div>

                <div class="row align-items-center">
                    <div class="col-lg-12">
                        
                         <div v-if="mensajeError != ''" class="alert alert-danger mb-4" role="alert">
                            <i class="fa fa-exclamation-triangle"></i> <strong>Un momento.</strong> <span>{{ mensajeError }}</span>
                        </div>

                        <div v-if="mensajeExito != ''" class="alert alert-success mb-4" role="alert">
                            <i class="fa fa-check"></i> <strong>Excelente.</strong> <span>{{ mensajeExito }}</span>
                        </div>

                        <div class="row">
                            <div class="col-lg-6">
                                <button type="button" class="default-btn-one submit-btn mb-3" @click="abrirModalTarjetas()">
                                    <i class="fa fa-th mr-2"></i>Agregar Carta<span></span>
                                </button>
                                <button type="button" class="default-btn-one submit-btn mb-3" @click="abrirModalFiltroTarjetas()">
                                    <i class="fa fa-th-list mr-2"></i>Agregar Varias Cartas<span></span>
                                </button>
                            </div>

                            <div class="col-lg-6 text-right">
                                <button type="button" class="default-btn-one submit-btn mb-3" @click="eliminarTodasTarjetas()">
                                    <i class="fa fa-trash mr-2"></i>Eliminar todas las Cartas<span></span>
                                </button>
                            </div>
                        </div>
                        
                        <div class="text-center">
                            <div class="row">
                                <div class="col-md-3 mb-3" v-for="set_card in sets_cards" :key="set_card.id">
                                    <div class="card">
										<div class="card-header text-center"><small>{{ set_card.name }} / {{ set_card.type }}</small></div>
										<div class="card-body"><img crossorigin="anonymous" :src="set_card.url_file" /></div>
										<div class="card-footer text-center">
											<a href="javascript:;" class="text-danger" @click="eliminarSetCard(set_card.id)"><i class="fa fa-trash"></i> Eliminar</a>
										</div>
									</div>
                                </div>

                                <div class="col-md-12" v-if="sets_cards.length == 0">
                                    <div class="text-center">No hay cartas en este set...</div>
                                </div>

                                <div class="col-md-12" :class="{ 'd-none': datosCargados == true }">
                                    <div class="text-center">Cargando listado...</div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </div>
        </section>


        <div class="modal bg-black" tabindex="-1" v-if="modalTarjetasOpened == true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Agregar Carta</h5>
                        <a href="javascript:;" type="button" class="btn-close" @click="cerrarModalTarjetas()"><i class="fa fa-times"></i></a>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Carta</label>
                            <select v-model="card_id" name="card_id" class="form-control" data-requerido="1">
                                <option value="">Seleccione una carta</option>
                                <option v-for="card in listado_cards" :key="card.id" :value="card.id">{{ card.name }}__________{{card.type}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="default-btn-one submit-btn" data-bs-dismiss="modal" @click="cerrarModalTarjetas()">Cerrar<span></span></button>
                        <button type="button" class="default-btn submit-btn" @click="agregarTarjeta()">Agregar<span></span></button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal bg-black" tabindex="-1" v-if="modalFiltroTarjetas">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Agregar Varias Cartas</h5>
                        <a href="javascript:;" type="button" class="btn-close" @click="cerrarModalFiltroTarjetas()"><i class="fa fa-times"></i></a>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Tipo</label>
                            <select v-model="filtro.tipo" name="tipo" class="form-control" data-requerido="1">
                                <option value="">Seleccione un tipo</option>
                                <option v-for="(tipo, index) in tipos" :key="index" :value="tipo">{{ tipo }}</option>
                            </select>
                        </div>
                        <div v-if="filtro.tipo == 'Sustantivo' || filtro.tipo == 'Color' || filtro.tipo == 'Numero' || filtro.tipo == 'Frase'" class="form-group">
                            <label>Frecuencia</label>
                            <select v-model="filtro.frecuencia" name="frecuencia" class="form-control" data-requerido="1">
                                <option value="">Todas</option>
                                <option v-for="(frecuencia, index) in frecuencias" :key="index" :value="frecuencia.valor">{{ frecuencia.nombre }}</option>
                            </select>
                        </div>
                        <div v-if="filtro.tipo == 'Sustantivo'" class="form-group">
                            <label>Categoría Semántica</label>
                            <select v-model="filtro.categoria_semantica" name="categoria_semantica" class="form-control" data-requerido="1">
                                <option value="">Todas</option>
                                <option v-for="(categoria, index) in categorias_semanticas" :key="index" :value="categoria">{{ categoria }}</option>
                            </select>
                        </div>
                        <div v-if="filtro.tipo == 'Adjetivo' || filtro.tipo == 'Par Minimo'" class="form-group">
                            <label>Posición</label>
                            <select v-model="filtro.posicion" name="posicion" class="form-control" data-requerido="1">
                                <option value="">Todas</option>
                                <option v-for="(posicion, index) in posiciones" :key="index" :value="posicion">{{ posicion }}</option>
                            </select>
                        </div>
                        <div v-if="filtro.tipo == 'Color'" class="form-group">
                            <label>Color</label>
                            <select v-model="filtro.color" name="color" class="form-control" data-requerido="1">
                                <option value="">Todos</option>
                                <option v-for="(color, index) in colores" :key="index" :value="color">{{ color }}</option>
                            </select>
                        </div>
                        <div v-if="filtro.tipo == 'Numero'" class="form-group">
                            <label>Cantidad</label>
                            <select v-model="filtro.cantidad" name="cantidad" class="form-control" data-requerido="1">
                                <option value="">Cualquiera</option>
                                <option v-for="(cantidad, index) in cantidades" :key="index" :value="cantidad">{{ cantidad }}</option>
                            </select>
                        </div>
                        <div v-if="filtro.tipo == 'Par Minimo'" class="form-group">
                            <label>Sílaba</label>
                            <select v-model="filtro.silaba" name="silaba" class="form-control" data-requerido="1">
                                <option value="">Cualquiera</option>
                                <option v-for="(silaba, index) in silabas" :key="index" :value="silaba">{{ silaba }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="default-btn-one submit-btn" data-bs-dismiss="modal" @click="cerrarModalFiltroTarjetas()">Cerrar<span></span></button>
                        <button v-if="filtro.tipo" type="button" class="default-btn submit-btn" @click="agregarVariasTarjetas()">Agregarlas al set<span></span></button>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>


<script>
    import TitleSection from '@/components/TitleSection'
    import Utilities from '@/plugins/Utilities.js'
    import axios from 'axios'

    const MyAccountSetsView = {
        name: 'MyAccountSetsView',
        components: {
            TitleSection,
        },
        data: function(){
            return {                
                datosCargados: true,
                mensajeExito: '',
                mensajeError: '',
                modalTarjetasOpened: false,
                modalFiltroTarjetas: false,

                sets_cards: [],
                listado_cards: [],

                tipos: ['Sustantivo', 'Adjetivo', 'Color', 'Numero', 'Par Minimo', 'Frase'],
                categorias_semanticas: ['alimentos', 'animales', 'herramientas', 'instrumentos musicales', 'lugares',
                                'medios de transporte', 'naturaleza', 'objetos comunes', 'objetos de la casa', 'partes del cuerpo',
                                'prendas de vestir', 'profesiones, edades y genero ', 'utensilios de cocina'],
                frecuencias: [{valor: 1, nombre: 'baja'}, {valor: 3, nombre: 'media'}, {valor: 5, nombre: 'alta'}],
                posiciones: ['derecha', 'izquierda'],
                colores: ['amarillo', 'azul', 'blanco', 'negro', 'rojo', 'verde'],
                cantidades: ['Uno', 'Dos', 'Tres', 'Cuatro', 'Cinco', 'Seis'],
                silabas: ['inicial', 'terminal'],

                filtro: {
                    tipo: '', // Sustantivo, Adjetivo, Color, Numero, Par Minimo, Frase
                    frecuencia: '', // Sustantivo, Color, Numero, Frase (1, 3 o 5)
                    categoria_semantica: '', // Sustantivo
                    posicion: '', // Adjetivo, Par Minimo
                    color: '', // Color
                    cantidad: '', // Numero
                    silaba: '', // Par minimo
                },

                id: '',
                name: '',

                card_type: '',
                card_count: '',
                card_id:''
            }
        },
        methods: {
            consultarData(){
                this.datosCargados = false
                this.mensajeExito = ''
                this.mensajeError = ''
                    
                let _this = this
                this.mensajeError = ''

                const formData = new FormData()
                formData.append('id', this.$route.params.id)

                axios.post(Utilities.getApiURL()  + 'sets/get', formData)
                .then(response => {
                    _this.datosCargados = true

                    if(response.data.code == '1'){
                        _this.id = response.data.id
                        _this.name = response.data.name

                    }
                    else{
                        _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                    }
                })
                .catch(error => {
                    _this.datosCargados = true
                    _this.mensajeError = error
                })
                
            },
            consultarSetsCards(){
                this.datosCargados = false
                this.mensajeExito = ''
                this.mensajeError = ''
                    
                let _this = this
                this.mensajeError = ''

                const formData = new FormData()
                formData.append('id', this.$route.params.id)

                axios.post(Utilities.getApiURL()  + 'sets_cards', formData)
                .then(response => {
                    _this.datosCargados = true

                    if(response.data.code == '1'){
                        _this.sets_cards = response.data.sets_cards
                        _this.listado_cards = response.data.listado_cards
                    }
                    else{
                        _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                    }
                })
                .catch(error => {
                    _this.datosCargados = true
                    _this.mensajeError = error
                })
                
            },
            eliminarSetCard(id){
                this.datosCargados = false
                this.mensajeExito = ''
                this.mensajeError = ''
                    
                let _this = this
                this.mensajeError = ''

                const formData = new FormData()
                formData.append('id', id)

                axios.post(Utilities.getApiURL()  + 'sets-cards/delete', formData)
                .then(response => {
                    _this.datosCargados = true

                    if(response.data.code == '1'){
                        _this.consultarSetsCards()

                        setTimeout(function(){
                            _this.mensajeExito = response.data.message
                        }, 1000)
                    }
                    else{
                        _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                    }
                })
                .catch(error => {
                    _this.datosCargados = true
                    _this.mensajeError = error
                })
                
            },
            eliminarTodasTarjetas(){
                if(confirm("¿Realmente desea eliminar todas las cartas de este set?")){
                    this.datosCargados = false
                    this.mensajeExito = ''
                    this.mensajeError = ''
                        
                    let _this = this
                    this.mensajeError = ''

                    const formData = new FormData()
                    formData.append('set_id', this.id)

                    axios.post(Utilities.getApiURL()  + 'sets_cards/delete-all', formData)
                    .then(response => {
                        _this.datosCargados = true

                        if(response.data.code == '1'){
                            _this.consultarSetsCards()

                            setTimeout(function(){
                                _this.mensajeExito = response.data.message
                            }, 1000)
                        }
                        else{
                            _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                        }
                    })
                    .catch(error => {
                        _this.datosCargados = true
                        _this.mensajeError = error
                    })
                }
            },
            abrirModalTarjetas(){
                this.modalTarjetasOpened = true
            },
            abrirModalFiltroTarjetas(){
                this.modalFiltroTarjetas = true
            },
            cerrarModalTarjetas(){
                this.modalTarjetasOpened = false
            },
            cerrarModalFiltroTarjetas(){
                this.modalFiltroTarjetas = false
            },
            agregarVariasTarjetas() {
                if(this.filtro.tipo){
                    this.datosCargados = false
                    this.mensajeExito = ''
                    this.mensajeError = ''
                        
                    let _this = this
                    this.mensajeError = ''
                    const formData = new FormData()
                    formData.append('tipo', this.filtro.tipo)
                    switch(this.filtro.tipo) {
                        case 'Sustantivo':
                            formData.append('frecuencia', this.filtro.frecuencia)
                            formData.append('categoria_semantica', this.filtro.categoria_semantica)
                            break;
                        case 'Adjetivo':
                            formData.append('posicion', this.filtro.posicion)
                            break;
                        case 'Color':
                            formData.append('frecuencia', this.filtro.frecuencia)
                            formData.append('color', this.filtro.color)
                            break;
                        case 'Numero':
                            formData.append('frecuencia', this.filtro.frecuencia)
                            formData.append('cantidad', this.filtro.cantidad)
                            break;
                        case 'Par Minimo':
                            formData.append('posicion', this.filtro.posicion)
                            formData.append('silaba', this.filtro.silaba)
                            break;
                        default:
                            formData.append('frecuencia', this.filtro.frecuencia)
                    }
                    formData.append('set_id', this.$route.params.id)
                    axios.post(Utilities.getApiURL()  + 'sets_cards/various/add', formData)
                    .then(response => {
                        _this.datosCargados = true

                        _this.cerrarModalFiltroTarjetas()
                        _this.card_id = ''

                        if(response.data.code == '1'){
                            
                            _this.consultarSetsCards()

                            setTimeout(function(){
                                _this.mensajeExito = response.data.message
                            }, 1000)
                        }
                        else{
                            _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                        }
                    })
                    .catch(error => {
                        _this.datosCargados = true
                        _this.mensajeError = error
                    })
                } else {
                    alert('Debes tener al menos un tipo')
                }
                
            },
            agregarTarjeta(){
                this.datosCargados = false
                this.mensajeExito = ''
                this.mensajeError = ''
                    
                let _this = this
                this.mensajeError = ''
                const formData = new FormData()
                formData.append('set_id', this.$route.params.id)
                formData.append('card_id', this.card_id)
                axios.post(Utilities.getApiURL()  + 'sets_cards/add', formData)
                .then(response => {
                    _this.datosCargados = true

                    _this.cerrarModalTarjetas()
                    _this.card_id = ''

                    if(response.data.code == '1'){
                        
                        _this.consultarSetsCards()

                        setTimeout(function(){
                            _this.mensajeExito = response.data.message
                        }, 1000)
                    }
                    else{
                        _this.mensajeError = response.data.message + '(' + response.data.code + ')'
                    }
                })
                .catch(error => {
                    _this.datosCargados = true
                    _this.mensajeError = error
                })
                
            },
        },
        beforeMount: function(){
			if(!Utilities.isLogged()){
                this.$router.push({name:'login'})
			}

            this.consultarData()
            this.consultarSetsCards()
		},
    }

    export default MyAccountSetsView;
</script>

<style scoped>
    .table tr:hover{
        cursor: pointer
    }

    .bg-black{
        background: rgba(0, 0, 0, 0.37)
    }

    .modal{
        display: initial;
    }

    .card-header{
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    
    .card-body{
        width: auto;
        height: 180px;
        background: #75072b;
    }
    
    .card-body img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
</style>