<template>
    <div>Logout</div>
</template>

<script>        	
    
    const Logout = {
        name: 'Logout',
        beforeMount: function(){
            sessionStorage.clear()
            
            // PARA ACTUALIZAR EL LAYOUT
            this.$emit('refreshLogued')
            
            this.$router.push({name:'login'})
        }
    }

    export default Logout
</script>