<template>
  <div>
    <!-- Start Page Title Area -->
    <div class="page-title-area item-bg1">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    
                </div>
            </div>
        </div>
    </div>
    <!-- End Page Title Area -->    
  </div>
</template>

<script>    
    const TitleSection = {
        name: 'title-section',
        components: {

        },
    }

    export default TitleSection;
</script>

<style scoped>

</style>